/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomQuote } from '../models/CustomQuote';
import type { Omit_RfqClientThreeplAssigned_id_ } from '../models/Omit_RfqClientThreeplAssigned_id_';
import type { OrderRfqQuoteAccept } from '../models/OrderRfqQuoteAccept';
import type { SuccessResponse_boolean_ } from '../models/SuccessResponse_boolean_';
import type { SuccessResponse_CustomQuote_at_id_ } from '../models/SuccessResponse_CustomQuote_at_id_';
import type { SuccessResponse_RfqClientThreeplAssigned_ } from '../models/SuccessResponse_RfqClientThreeplAssigned_';
import type { SuccessResponse_RfqClientThreeplAssigned_Array_or_undefined_ } from '../models/SuccessResponse_RfqClientThreeplAssigned_Array_or_undefined_';
import type { UuidV4 } from '../models/UuidV4';
import { request as __request } from '../core/request';

export class OrderQuoteService {

    /**
     * Accept Order Rfq Quote by 3PL.
     * @param requestBody The data
     * @returns SuccessResponse_boolean_ OK
     * @throws ApiError
     */
    public static async acceptOrderRfqQuoteThreePl(
requestBody: OrderRfqQuoteAccept,
): Promise<SuccessResponse_boolean_> {
        const result = await __request({
            method: 'POST',
            path: `/accept-order-rfq-quote-3pl`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Add an order quote status.
     * @param requestBody The order quote status data
     * @returns SuccessResponse_RfqClientThreeplAssigned_ OK
     * @throws ApiError
     */
    public static async addRfqClientThreeplAssigned(
requestBody: Omit_RfqClientThreeplAssigned_id_,
): Promise<SuccessResponse_RfqClientThreeplAssigned_> {
        const result = await __request({
            method: 'POST',
            path: `/add-order-rfq-client-3pl-assigned`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Add an order quote.
     * @param requestBody The order quote data
     * @returns SuccessResponse_CustomQuote_at_id_ OK
     * @throws ApiError
     */
    public static async addOrderRfqQuoteThreePl(
requestBody: CustomQuote,
): Promise<SuccessResponse_CustomQuote_at_id_> {
        const result = await __request({
            method: 'POST',
            path: `/add-order-rfq-quote-3pl`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Cancel a custom quote.
     * @param requestBody The order quote data
     * @returns SuccessResponse_boolean_ OK
     * @throws ApiError
     */
    public static async cancelCustomQuote(
requestBody: {
orderId: UuidV4,
},
): Promise<SuccessResponse_boolean_> {
        const result = await __request({
            method: 'POST',
            path: `/cancel-custom-quote`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Cancel an order quote.
     * @param requestBody The order quote data
     * @returns SuccessResponse_boolean_ OK
     * @throws ApiError
     */
    public static async cancelOrderRfqQuoteThreePl(
requestBody: {
rfqId: string,
},
): Promise<SuccessResponse_boolean_> {
        const result = await __request({
            method: 'POST',
            path: `/cancel-order-rfq-quote-3pl`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Decline Quote by 3PL.
     * @param requestBody The data
     * @returns SuccessResponse_boolean_ OK
     * @throws ApiError
     */
    public static async declineOrderRfqQuoteThreePl(
requestBody: {
rfqId: string,
},
): Promise<SuccessResponse_boolean_> {
        const result = await __request({
            method: 'POST',
            path: `/decline-order-rfq-quote-3pl`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get the quote status of a 3PL.
     * @param rfqId The RFQ ID
     * @param clientOrganizationId 
     * @param threePlOrganizationId 
     * @returns SuccessResponse_RfqClientThreeplAssigned_Array_or_undefined_ OK
     * @throws ApiError
     */
    public static async getRfqClientThreeplAssigned(
rfqId: string,
clientOrganizationId?: string,
threePlOrganizationId?: string,
): Promise<SuccessResponse_RfqClientThreeplAssigned_Array_or_undefined_> {
        const result = await __request({
            method: 'GET',
            path: `/rfq-client-3pl-assigned`,
            query: {
                'rfqId': rfqId,
                'clientOrganizationId': clientOrganizationId,
                'threePlOrganizationId': threePlOrganizationId,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}