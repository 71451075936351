import {
    type AccessorialLocationType,
    type AccessorialType,
    type AddressType,
    type B13aFilingOption,
    type BillDutiesToParty,
    type CarrierType,
    type Country,
    type CoverageCommodityType,
    type Currency,
    type DensityUnit,
    type DimensionUnit,
    type DocumentType,
    type EquipmentType,
    type ExportComplianceStatement,
    type HazmatClass,
    type HazmatMode,
    type ImportExportType,
    type IncotermsType,
    type InfoMessageSeverity,
    type InvoiceEdiSentStatus,
    type InvoiceStatus,
    type InvoiceType,
    type MarginVersion,
    type NonDeliveryHandlingType,
    type OrderStatus,
    type OrganizationType,
    type PackageType,
    type PaymentTypeOption,
    type Provider,
    type ReasonForExportType,
    type RfqStatus,
    type ServiceLevelType,
    type ServiceType,
    type ShipmentIdentifierSource,
    type ShipmentIdentifierType,
    ShipmentStatus,
    type TariffType,
    type TrackingUpdateExceptionDetail,
    type TrackingUpdateExceptionSummary,
    type TrackingUpdateImageFormat,
    type TrackingUpdateImageType,
    type TrackingUpdateStatus,
    type TrackingUpdateStatusReason,
    type TrackingUpdateStopArrival,
    type TrackingUpdateStopStatus,
    type TrackingUpdateStopType,
    type TrackingUpdateType,
    type TransportType,
    type USMCA,
    type UuidV4,
    type UuidV5,
    type VolumeUnit,
    type WeightUnit,
    ShipmentDocumentType,
    QuoteCoverageType
} from '@lazr/openapi-client'

import { OtherUser, type OtherUserProps } from './OtherUser'
import { BatteryMaterial, BatteryPacking, HazmatAccessibiltyType, LabelSizeType, ShippingMode } from '@lazr/enums'
import { HoldForPickupAddress } from './Search'
import { ResultLaneType } from '@lazr/openapi-client'

export interface OrderInfoOrganization {
    id: UuidV4
    type: OrganizationType
    name: string
    state: string
    country: string
    exemptFromTaxes?: boolean
}
export interface OrderInfoLogDispatch {
    successful: boolean
    error: string | null
}

interface OrderInfoPriceReviewCharge {
    id: UuidV4
    priceReviewId: UuidV4
    seqNumber: number
    rawCad?: number
    rawUsd?: number
    totalCad: number
    totalUsd: number
    code: string
    description: string
}

export interface OrderInfoPriceReview {
    id: UuidV4
    orderId: UuidV4
    rawCad?: number
    rawUsd?: number
    totalCad: number
    totalUsd: number
    currency: Currency
    updatedAt: string
    createdAt: string
    createdByUserId: UuidV4
    charges: OrderInfoPriceReviewCharge[]
    invoiceCsvBatchId: UuidV4 | null
}

export interface OrderInfoDocument {
    id: UuidV4
    name: string
    type: DocumentType
    orderId: UuidV4
    organizationId: UuidV4
    updatedAt: string
    createdAt: string
    createdByUserId: UuidV4
    isGenerated: boolean
    hasBarcode: boolean
    transportInvoiceNumber: string | null
    signedUrl: string
}

interface OrderInfoTrackingUpdateInfoMessage {
    id: UuidV4
    trackingUpdateId: UuidV4
    source: string
    message: string
    severity: InfoMessageSeverity
    diagnostic: string
    updatedAt: string
    createdAt: string
}

interface OrderInfoTrackingUpdateImage {
    id: UuidV4
    trackingUpdateId: UuidV4
    type: TrackingUpdateImageType
    format: TrackingUpdateImageFormat
    imageUrl: string
    updatedAt: string
    createdAt: string
}

interface OrderInfoTrackingUpdateStop {
    id: UuidV4
    trackingUpdateId: UuidV4
    apiStopId: string
    number: number
    type: TrackingUpdateStopType
    status: TrackingUpdateStopStatus
    arrival: TrackingUpdateStopArrival
    estimatedArrivalStartDate: string
    estimatedArrivalEndDate: string
    arrivalDate: string
    departureDate: string
    updatedAt: string
    createdAt: string
}

export interface OrderInfoTrackingUpdate {
    id: UuidV4
    shipmentId: UuidV4
    trackingUpdateTime: string
    trackingUpdateTimeSet: boolean
    retrievalDateTime: string
    type: TrackingUpdateType
    status: TrackingUpdateStatus
    statusReason: TrackingUpdateStatusReason
    statusMessage: string
    exceptionSummary: TrackingUpdateExceptionSummary
    exceptionDetail: TrackingUpdateExceptionDetail
    stopType: TrackingUpdateStopType
    stopNumber: number
    apiStopId: string
    streetAddress: string
    city: string
    state: string
    country: {
        code: Country
        name: string
        isSupported: boolean
    }
    postalCode: string
    latitude: number
    longitude: number
    updatedAt: string
    createdAt: string
    createdByUserId: UuidV4
    trackingUpdateInfoMessages: OrderInfoTrackingUpdateInfoMessage[]
    trackingUpdateImages: OrderInfoTrackingUpdateImage[]
    trackingUpdateStops: OrderInfoTrackingUpdateStop[]
}

export interface OrderInfoShipmentIdentifier {
    id: UuidV4
    shipmentId: UuidV4
    source: ShipmentIdentifierSource
    type: ShipmentIdentifierType
    value: string
    primaryForType: boolean
    updatedAt: string
    createdAt: string
    createdByUserId: UuidV4
}

interface OrderInfoShipmentInfoMessage {
    createdAt: string
    updatedAt: string
    diagnostic: string
    severity: InfoMessageSeverity
    message: string
    source: string
    id: UuidV4
}

interface ReferenceOrder {
    id: UuidV4
    number: string
}

export interface OrderInfoShipment {
    id: UuidV4
    orderId: UuidV4
    lastUpdatedByUserId: UuidV4
    lastUpdatedAt: string
    status: ShipmentStatus
    estimatedDeliveryDate: string
    carrierId: UuidV4
    transitDaysMin: number
    transitDaysMax: number
    apiId: string
    apiLegId: string
    apiPickupDateTime: string
    apiBillOfLadingUrl: string
    apiPackingVisualizationUrl: string
    specialInstructionsForPickup: string
    specialInstructionsForDelivery: string
    pullTracking: boolean
    trackingEngaged: boolean
    updatedAt: string
    createdAt: string
    shipmentInfoMessages: OrderInfoShipmentInfoMessage[]
    trackingUpdates: OrderInfoTrackingUpdate[]
    shipmentIdentifiers: OrderInfoShipmentIdentifier[]
}

interface OrderInfoQuoteCharge {
    id: UuidV4
    quoteId: UuidV4
    seqNumber: number
    code: string
    description: string
    rawCad?: number
    rawUsd?: number
    marginCad?: number
    marginUsd?: number
    totalCad: number
    totalUsd: number
}

interface OrderInfoQuoteCarrierService {
    id: UuidV4
    carrierId: UuidV4
    transportType: TransportType
    ratingServiceType: ServiceType
    dispatchServiceType: ServiceType
    trackingServiceType: ServiceType
    imagingServiceType: ServiceType
    supportEtd: boolean
    supportEtdDeletion: boolean
    updatedAt: string
    createdAt: string
}
export interface OrderInfoQuoteCarrierInfo {
    id: UuidV4
    name: string
    companyName: string
    carrierId: UuidV4
    organizationId: UuidV4 | null
    xeroCadContactId: UuidV4
    xeroUsdContactId: UuidV4
    streetAddressLine1: string
    streetAddressLine2: string
    streetAddressLine3: string
    city: string
    state: string
    country: {
        code: Country
        name: string
    }
    postalCode: string
    createdByUserId: UuidV4
    updatedAt: string
    createdAt: string
    paymentTermsDays: number
}
export interface OrderInfoQuoteCarrier {
    id: UuidV4
    name: string
    apiCarrierAccountCode: string
    type: CarrierType
    idValue: string
    updatedAt: string
    createdAt: string
    quoteCarrierService: OrderInfoQuoteCarrierService
    etdUploadMaxSize: number
}

interface ResultShippingMode {
    code: ShippingMode
    name: string
}

export interface OrderInfoQuote {
    id: UuidV4
    rfqId: UuidV4
    isCustomQuote: boolean
    requiresCustomQuote: boolean
    requiresValidation: boolean
    provider: Provider
    apiCarrierQuoteNumber: string
    carrierId?: UuidV4
    carrierCode?: string
    carrierDisplayName?: string
    serviceLevel: string
    serviceLevelCode: string
    currency: Currency
    preferredCurrency: Currency
    lazrCadToUsdRate: number
    lazrUsdToCadRate: number
    exchangedAt: string
    transitDaysMin: number
    transitDaysMax: number
    costRawCad?: number
    costRawUsd?: number
    costMarginCad?: number
    costMarginUsd?: number
    costTotalCad: number
    costTotalUsd: number
    costTransactionFeeCad?: number
    costTransactionFeeUsd?: number
    additionalInfo: string
    estimatedTimeOfArrival: string
    estimatedDeliveryDate: string | null
    validUntil: string
    updatedAt: string
    createdAt: string
    createdByUserId: UuidV4
    tariffType: TariffType
    lazrShareFixedFeeCad: number
    lazrShareFixedFeeUsd: number
    lazrSharePercentFee: number
    threePlOrganizationId: string
    threePlOrganizationName: string
    quoteCharges: OrderInfoQuoteCharge[]
    quoteCarrier: OrderInfoQuoteCarrier
    clientSpecific: boolean
    isHidden: boolean
    isPriceAdjusted?: boolean
    organizationProviderAccountId: UuidV4
    marginVersion: MarginVersion
    serviceLevelType: ServiceLevelType | null
    accountNumber: string | null
    shippingMode: ResultShippingMode | null
    laneType: ResultLaneType | null
}

interface OrderInfoAccessorial {
    id: UuidV4
    code: string
    name: string
    type: AccessorialType
    transportTypes: TransportType[]
    locationType: AccessorialLocationType
    forceCustomQuote: boolean
}

interface OrderInfoHandlingUnitAccessorial {
    id: UuidV4
    handlingUnitId: UuidV4
    accessorial: OrderInfoAccessorial
    isRemovable: boolean
}

export interface OrderInfoRfqAccessorial {
    id: UuidV4
    rfqId: UuidV4
    accessorial: OrderInfoAccessorial
    isRemovable: boolean
}

export interface OrderInfoHandlingUnit {
    id: UuidV4
    rfqId: UuidV4
    quantity: number
    pieces: number
    length: number
    lengthUnit: DimensionUnit
    width: number
    widthUnit: DimensionUnit
    height: number
    heightUnit: DimensionUnit
    weight: number
    weightUnit: WeightUnit
    totalWeight: number
    totalWeightUnit: WeightUnit
    totalVolume: number
    totalVolumeUnit: VolumeUnit
    density: number
    densityUnit: DensityUnit
    class: string
    nmfc: string
    nmfcSub: string
    packageType: {
        code: PackageType
        singularName: string
        pluralName: string
    }
    description: string
    poNumber: string | null
    handlingUnitAccessorials: OrderInfoHandlingUnitAccessorial[]
    hazmatAccessibiltyType: HazmatAccessibiltyType | null
    dryIce: {
        unit: WeightUnit
        value: number
    } | null
    battery: {
        material: BatteryMaterial
        packing: BatteryPacking
    } | null
    freightHazmatUn: string | null,
    freightHazmatScientificName: string | null,
    freightHazmatPackagingGroup: string | null,
    freightHazmatEmergencyContactNumber: string | null,
    freightHazmatClass: string | null,
}

export interface OrderInfoCustomsDetail {
    id: UuidV4
    handlingUnitIds: UuidV4[]
    description: string
    harmonizedCode: string | null
    countryOfManufacture: {
        code: Country
        name: string
    }
    unitValue: number
    unitCurrency: Currency | null
    quantity: number
    productCode: string
    productIndicatorIsUsmca: boolean
    productIndicatorFda: boolean
    productIndicatorTextile: boolean
    documentIndicatorUsmca: boolean
    documentIndicatorFda: boolean
    documentIndicatorFcc: boolean
    senderIsProducerIndicator: boolean
    textileManufacturer: string | null
    unitWeight: number
    weightUnit: WeightUnit | null
    pieces: number | null
    productIndicatorUsmcaType: USMCA
    squareMeters: number | null
}

export interface OrderInfoRfq {
    id: UuidV4
    orderId: UuidV4
    addressId: UuidV4
    billingPo: string | null
    submittedAt: string
    bookedAt: string
    status: {
        code: RfqStatus
        name: string
    }
    customQuoteRequested: boolean
    transportType: {
        code: TransportType
        name: string
    }
    equipmentType: {
        code: EquipmentType
        name: string
    }
    paymentType: PaymentTypeOption
    selectedQuoteId: UuidV4
    originStreetAddress: string
    originStreetAddress2?: string | null
    originStreetAddress3?: string | null
    originCity: string
    originState: string
    originCountry: {
        code: Country
        name: string
        isSupported: boolean
    }
    originPostalCode: string
    originAddressType: AddressType | null
    originPickupDate: string
    originName: string
    originContactName: string
    originContactEmails: string[]
    originContactPhone: string
    originContactPhoneExtension: string
    originCompanyName: string
    originNotifyShipper: boolean
    originPickupOpenTime: string
    originPickupCloseTime: string
    originPickupInstructions: string
    originPoNumber: string
    destinationStreetAddress: string
    destinationStreetAddress2?: string | null
    destinationStreetAddress3?: string | null
    destinationCity: string
    destinationState: string
    destinationCountry: {
        code: Country
        name: string
        isSupported: boolean
    }
    destinationPostalCode: string
    destinationAddressType: AddressType | null
    destinationDeliveryDate: string
    destinationName: string
    destinationContactName: string
    destinationContactEmails: string[]
    destinationContactPhone: string
    destinationContactPhoneExtension: string
    destinationCompanyName: string
    destinationNotifyReceiver: boolean
    destinationDeliveryOpenTime: string
    destinationDeliveryCloseTime: string
    destinationDeliveryInstructions: string
    destinationPoNumber: string
    customsBroker: string
    linearFeet: number
    skidSpots: number
    isBookedWithCoverage: boolean
    isCoverageExclusionsAccepted?: boolean
    isBolUploaded: boolean
    coverageCommodityType: {
        code: CoverageCommodityType
        name: string
    }
    shipmentCoverageValue: number
    reverseMargins: boolean
    resultsSearchStartedAt: string
    resultsSearchInProgress: boolean
    resultsCompleted: Record<string, boolean>
    lastUpdatedByUserId: UuidV4
    submittedByUserId: UuidV4
    updatedAt: string
    createdAt: string
    quotes: OrderInfoQuote[]
    mappedReasons: Record<string, OrderInfoRfqReason[]>
    handlingUnits: OrderInfoHandlingUnit[]
    customsDetails?: OrderInfoCustomsDetail[]
    rfqAccessorials: OrderInfoRfqAccessorial[]
    billDutiesToParty: {
        code: BillDutiesToParty
        name: string
    } | null
    incotermsType: {
        code: IncotermsType
        name: string
        version: string
    } | null
    commercialInvoiceDocumentIndicator: boolean | null
    commercialInvoiceUserProvided: boolean | null
    documentsOnlyIndicator: boolean | null
    customsTotalValue: number | null
    dutyBillToCity: string | null
    dutyBillToContactEmail: string | null
    dutyBillToCompanyName: string | null
    dutyBillToContactName: string | null
    dutyBillToContactPhone: string | null
    dutyBillToContactPhoneExt: string | null
    dutyBillToCountry: {
        code: Country
        name: string
    } | null
    dutyBillToName: string | null
    dutyBillToPostalCode: string | null
    dutyBillToState: string | null
    dutyBillToStreetAddress: string | null
    dutyBillToStreetAddress2: string | null
    dutyBillToStreetAddress3: string | null
    dutyBillToAccountNumber: string | null
    dutyBusinessRelated: boolean | null
    dutyCurrency: Currency | null
    hazmatClass: {
        code: HazmatClass
        name: string
    } | null
    hazmatDeclarationDocumentIndicator: boolean | null
    hazmatMode: {
        code: HazmatMode
        name: string
    } | null
    importExport: {
        code: ImportExportType
        name: string
    } | null
    etdEnabled: boolean | null
    b13aFilingOption: {
        code: B13aFilingOption
        name: string
    } | null
    useThermalPrinter: boolean | null
    labelSizeType: LabelSizeType | null
    quoteCarrierInfoList: OrderInfoQuoteCarrierInfo[]
    quoteCoverageType: QuoteCoverageType | null
    reasonForExport: {
        code: ReasonForExportType
        name: string
    } | null
    nonDeliveryHandling: {
        code: NonDeliveryHandlingType
        name: string
    } | null
    otherReasonForExport: string | null
    exportComplianceStatement: {
        code: ExportComplianceStatement
        name: string
    } | null
    customsBrokerCity: string | null
    customsBrokerEmail: string | null
    customsBrokerContactName: string | null
    customsBrokerContactPhone: string | null
    customsBrokerContactPhoneExt: string | null
    customsBrokerCountry: {
        code: Country
        name: string
    } | null
    customsBrokerPostalCode: string | null
    customsBrokerState: string | null
    customsBrokerStreetAddress: string | null
    customsBrokerStreetAddress2: string | null
    customsBrokerStreetAddress3: string | null
    customsBrokerAccountNumber: string | null
    taxIdentificationNumber: string | null
    consigneeTaxIdentificationNumber: string | null
    thirdPartyTaxIdentificationNumber: string | null
    schedulePickup: boolean | null
    existingPickups?: {
        pickupNumber: string
        organizationProviderAccountId: UuidV4
        carrierId: UuidV4
        provider: Provider
    }[]
    shipmentDocumentType: ShipmentDocumentType | null
    holdForPickup: {
        carrierId: UuidV4 | null
        address: HoldForPickupAddress | null
    }
}
export interface OrderInfoRfqProps {
    id: UuidV4
    addressId: UuidV4
    billingPo: string | null
    orderId: UuidV4
    submittedAt: string
    bookedAt: string
    status: {
        code: RfqStatus
        name: string
    }
    customQuoteRequested: boolean
    transportType: {
        code: TransportType
        name: string
    }
    equipmentType: {
        code: EquipmentType
        name: string
    }
    paymentType: PaymentTypeOption
    selectedQuoteId: UuidV4
    originStreetAddress: string
    originStreetAddress2: string
    originStreetAddress3: string
    originCity: string
    originState: string
    originCountry: {
        code: Country
        name: string
        isSupported: boolean
    }
    originPostalCode: string
    originAddressType: AddressType | null
    originPickupDate: string
    originName: string
    originContactName: string
    originContactEmails: string[]
    originContactPhone: string
    originContactPhoneExtension: string
    originCompanyName: string
    originNotifyShipper: boolean
    originPickupOpenTime: string
    originPickupCloseTime: string
    originPickupInstructions: string
    originPoNumber: string
    destinationStreetAddress: string
    destinationStreetAddress2: string
    destinationStreetAddress3: string
    destinationCity: string
    destinationState: string
    destinationCountry: {
        code: Country
        name: string
        isSupported: boolean
    }
    destinationPostalCode: string
    destinationAddressType: AddressType | null
    destinationDeliveryDate: string
    destinationName: string
    destinationContactName: string
    destinationContactEmails: string[]
    destinationContactPhone: string
    destinationContactPhoneExtension: string
    destinationCompanyName: string
    destinationNotifyReceiver: boolean
    destinationDeliveryOpenTime: string
    destinationDeliveryCloseTime: string
    destinationDeliveryInstructions: string
    destinationPoNumber: string
    customsBroker: string
    linearFeet: number
    skidSpots: number
    isBookedWithCoverage: boolean
    isCoverageExclusionsAccepted?: boolean
    isBolUploaded: boolean
    coverageCommodityType: {
        code: CoverageCommodityType
        name: string
    }
    shipmentCoverageValue: number
    reverseMargins: boolean
    resultsSearchStartedAt: string
    resultsSearchInProgress: boolean
    resultsCompleted: Record<string, boolean>
    lastUpdatedByUserId: UuidV4
    submittedByUserId: UuidV4
    updatedAt: string
    createdAt: string
    quotes: OrderInfoQuote[]
    reasons: OrderInfoRfqReason[]
    handlingUnits: OrderInfoHandlingUnit[]
    customsDetails?: OrderInfoCustomsDetail[]
    rfqAccessorials: OrderInfoRfqAccessorial[]
    billDutiesToParty: {
        code: BillDutiesToParty
        name: string
    } | null
    incotermsType: {
        code: IncotermsType
        name: string
        version: string
    } | null
    etdEnabled: boolean | null
    commercialInvoiceDocumentIndicator: boolean | null
    commercialInvoiceUserProvided: boolean | null
    documentsOnlyIndicator: boolean | null
    customsTotalValue: number | null
    dutyBillToCity: string | null
    dutyBillToContactEmail: string | null
    dutyBillToCompanyName: string | null
    dutyBillToContactName: string | null
    dutyBillToContactPhone: string | null
    dutyBillToContactPhoneExt: string | null
    dutyBillToCountry: {
        code: Country
        name: string
    } | null
    dutyBillToName: string | null
    dutyBillToPostalCode: string | null
    dutyBillToState: string | null
    dutyBillToStreetAddress: string | null
    dutyBillToStreetAddress2: string | null
    dutyBillToStreetAddress3: string | null
    dutyBillToAccountNumber: string | null
    dutyBusinessRelated: boolean | null
    dutyCurrency: Currency | null
    hazmatClass: {
        code: HazmatClass
        name: string
    } | null
    hazmatDeclarationDocumentIndicator: boolean | null
    hazmatMode: {
        code: HazmatMode
        name: string
    } | null
    importExport: {
        code: ImportExportType
        name: string
    } | null
    b13aFilingOption: {
        code: B13aFilingOption
        name: string
    } | null
    useThermalPrinter: boolean | null
    labelSizeType: LabelSizeType | null
    quoteCarrierInfoList: OrderInfoQuoteCarrierInfo[]
    reasonForExport: {
        code: ReasonForExportType
        name: string
    } | null
    nonDeliveryHandling: {
        code: NonDeliveryHandlingType
        name: string
    } | null
    otherReasonForExport: string | null
    exportComplianceStatement: {
        code: ExportComplianceStatement
        name: string
    } | null
    customsBrokerCity: string | null
    customsBrokerEmail: string | null
    customsBrokerContactName: string | null
    customsBrokerContactPhone: string | null
    customsBrokerContactPhoneExt: string | null
    customsBrokerCountry: {
        code: Country
        name: string
    } | null
    customsBrokerPostalCode: string | null
    customsBrokerState: string | null
    customsBrokerStreetAddress: string | null
    customsBrokerStreetAddress2: string | null
    customsBrokerStreetAddress3: string | null
    customsBrokerAccountNumber: string | null
    taxIdentificationNumber: string | null
    consigneeTaxIdentificationNumber: string | null
    thirdPartyTaxIdentificationNumber: string | null
    schedulePickup: boolean | null
    existingPickups?: {
        pickupNumber: string
        organizationProviderAccountId: UuidV4
        carrierId: UuidV4
        provider: Provider
    }[]
    shipmentDocumentType: ShipmentDocumentType | null
    holdForPickup: {
        carrierId: UuidV4 | null
        address: HoldForPickupAddress | null
    }
    quoteCoverageType: QuoteCoverageType | null
}

export interface Collect {
    id?: UuidV4
    carrierId?: UuidV4
    orderId?: UuidV4
    carrierName?: string
    accountNumber: string
}

interface InvoicePackageType {
    code: PackageType
    singularName: string
    pluralName: string
}

interface InvoiceChargeItem {
    id: UuidV4
    qty?: number
    units?: InvoicePackageType
    description: string
    weight?: number
    weightUnit?: WeightUnit
    totalWeight?: number
    totalWeightUnit?: WeightUnit
    class?: string
    total: number
    code: string
}

export interface OrderInfoRfqReasonCarrier {
    id: UuidV4
    name: string
    idValue: string
    type: CarrierType
    apiCarrierAccountCode: string
    createdAt: string
    updatedAt: string
    services?: {
        transportType: TransportType
    }[]
}

export interface OrderInfoRfqReason {
    carrier: OrderInfoRfqReasonCarrier
    tariffType: TariffType
    createdByUserId: UuidV4
    createdAt: string
    updatedAt: string
    serviceLevelCode: string
    serviceLevel: string
    carrierCode: string
    error: string
    carrierId: UuidV4
    provider: Provider
    rfqId: UuidV4
    id: UuidV4
    clientSpecific: boolean
    threePlOrganizationId: string
}

interface InvoiceCreatedByUser {
    id: UuidV4
    shortFullName: string
}

export interface OrderInfoInvoice {
    id: UuidV4
    consolidationId: UuidV4
    externalNumber: string
    invoicePrice: number
    type: InvoiceType
    currency: Currency
    pickupDate: string
    taxes: {
        id: UuidV4
        total: number
        type: string
    }[]
    createdAt: string
    status: InvoiceStatus
    chargeItems: InvoiceChargeItem[]
    createdByUser: InvoiceCreatedByUser
    invoiceEdiSentStatus: InvoiceEdiSentStatus | null
}

export interface OrderInfoProps {
    billingCurrency: Currency
    lazrCadToUsdRate: number
    clientNumber: string
    clientOrganization: OrderInfoOrganization
    clientPrivateNotes: string
    closingAt: string | null
    createdAt: string
    createdByUser: OtherUserProps | null
    documents: OrderInfoDocument[]
    id: UuidV4
    isOpen: boolean
    lastOpenAt: string
    lastUpdatedByUser: OtherUserProps
    number: string
    openUser: OtherUserProps | null
    priceReviews: OrderInfoPriceReview[]
    shipments: OrderInfoShipment[]
    rfq: OrderInfoRfqProps
    status: OrderStatus
    systemPrivateNotes: string
    threePlNumber: string
    threePlOrganization?: OrderInfoOrganization
    threePlAgent: OtherUserProps | null
    threePlPrivateNotes: string
    updatedAt: string
    lazrUsdToCadRate: number
    ediOrderId: null | UuidV5
    isManualDispatchAllowed: boolean
    invoices: OrderInfoInvoice[]
    completeReason: string | null
    logDispatches?: OrderInfoLogDispatch[]
    collectData: Collect | null
    isReturn?: boolean 
    returnReasons?: string | null
    referenceOrder? : ReferenceOrder | null
}

export class OrderInfo {
    public billingCurrency: Currency
    public lazrCadToUsdRate: number
    public clientNumber: string
    public clientOrganization: OrderInfoOrganization
    public clientPrivateNotes: string
    public closingAt: string | null
    public createdAt: string
    public createdByUser: OtherUser | null
    public documents: OrderInfoDocument[]
    public id: UuidV4
    public isOpen: boolean
    public lastOpenAt: string
    public lastUpdatedByUser: OtherUser | null
    public number: string
    public openUser: OtherUser | null
    public priceReviews: OrderInfoPriceReview[]
    public shipments: OrderInfoShipment[]
    public rfq: OrderInfoRfq
    public status: OrderStatus
    public systemPrivateNotes: string
    public threePlNumber: string
    public threePlOrganization?: OrderInfoOrganization
    public threePlAgent: OtherUser | null
    public threePlPrivateNotes: string
    public updatedAt: string
    public lazrUsdToCadRate: number
    public ediOrderId: null | UuidV5
    public isManualDispatchAllowed: boolean
    public invoices: OrderInfoInvoice[]
    public completeReason: string | null
    public logDispatches?: OrderInfoLogDispatch[]
    public bookedAt?: string
    public originStreetAddress?: string
    public originStreetAddress2?: string
    public originCity?: string
    public originState?: string
    public originCountry?: string
    public originPostalCode?: string
    public originPoNumber?: string
    public destinationStreetAddress?: string
    public destinationStreetAddress2?: string
    public destinationCity?: string
    public destinationState?: string
    public destinationCountry?: string
    public destinationPostalCode?: string
    public destinationPoNumber?: string
    public collectAccountNumber: string | null
    public isReturn?: boolean
    public returnReasons?: string | null
    public referenceOrder? : ReferenceOrder | null

    constructor (props: OrderInfoProps) {
        this.billingCurrency = props.billingCurrency
        this.lazrCadToUsdRate = props.lazrCadToUsdRate
        this.clientNumber = props.clientNumber
        this.clientOrganization = props.clientOrganization
        this.clientPrivateNotes = props.clientPrivateNotes
        this.closingAt = props.closingAt
        this.createdAt = props.createdAt
        this.createdByUser = props.createdByUser && new OtherUser(props.createdByUser)
        this.documents = props.documents
        this.id = props.id
        this.isOpen = props.isOpen
        this.lastOpenAt = props.lastOpenAt
        this.lastUpdatedByUser = props.lastUpdatedByUser && new OtherUser(props.lastUpdatedByUser)
        this.number = props.number
        this.openUser = props.openUser && new OtherUser(props.openUser)
        this.priceReviews = props.priceReviews
        this.shipments = props.shipments
        this.logDispatches = props.logDispatches
        this.rfq = {
            ...props.rfq,
            mappedReasons: this.mapReasonsToProvider(props.rfq.reasons),
        }
        this.status = props.status
        this.systemPrivateNotes = props.systemPrivateNotes
        this.threePlNumber = props.threePlNumber
        this.threePlOrganization = props.threePlOrganization
        this.threePlAgent = props.threePlAgent && new OtherUser(props.threePlAgent)
        this.threePlPrivateNotes = props.threePlPrivateNotes
        this.updatedAt = props.updatedAt
        this.lazrUsdToCadRate = props.lazrUsdToCadRate
        this.ediOrderId = props.ediOrderId
        this.isManualDispatchAllowed = props.isManualDispatchAllowed
        this.invoices = props.invoices
        this.completeReason = props.completeReason
        this.collectAccountNumber = props.collectData?.accountNumber ?? null
        this.isReturn = props?.isReturn 
        this.returnReasons= props?.returnReasons || null
        this.referenceOrder = props?.referenceOrder || null
    }

    get selectedQuote (): OrderInfoQuote | null {
        if (!this.rfq.selectedQuoteId) {
            return null
        }

        return this.rfq.quotes.find((quote) => quote.id === this.rfq.selectedQuoteId) ?? null
    }
    get activeShipment (): OrderInfoShipment | null {
        return this.shipments.find((shipment) => shipment.status === ShipmentStatus.ACTIVE) ?? null
    }

    private mapReasonsToProvider (reasons: OrderInfoRfqReason[]): Record<string, OrderInfoRfqReason[]> {
        const mappedReasons: Record<string, OrderInfoRfqReason[]> = {}
        reasons.forEach((reason) => {
            if (!mappedReasons[reason.provider]) {
                mappedReasons[reason.provider] = []
            }
            mappedReasons[reason.provider].push(reason)
        })

        return mappedReasons
    }
}
