export enum AccessorialType {
    COMMODITY = 'COMMODITY',
    LOCATION = 'LOCATION',
    SERVICE = 'SERVICE'
}

export enum AccessorialLocationType {
    ORIGIN = 'ORIGIN',
    DESTINATION = 'DESTINATION',
    ANY = 'ANY'
}

export enum AddressType {
    RESIDENTIAL = 'RESIDENTIAL',
    BUSINESS = 'BUSINESS'
}

export enum CarrierType {
    SYSTEM = 'SYSTEM',
    SCAC = 'SCAC'
}
export enum QuickBooksContactType {
    VENDOR = 'VENDOR',
    CUSTOMER = 'CUSTOMER'
}
export enum CoverageCommodityType {
    GENERAL = 'GENERAL',
    FRAGILE = 'FRAGILE',
    CONSUMER_ELECTRONICS = 'CONSUMER_ELECTRONICS',
    TEMPERATURE_CONTROLLED = 'TEMPERATURE_CONTROLLED',
    LIGHT_MACHINERY = 'LIGHT_MACHINERY',
    CARS = 'CARS',
    HVAC = 'HVAC'
}

export enum DensityUnit {
    LB_FT3 = 'LB_FT3',
    KG_M3 = 'KG_M3'
}

export enum DimensionUnit {
    IN = 'IN',
    CM = 'CM'
}

export enum DocumentType {
    BILL_OF_LADING = 'BILL_OF_LADING',
    LOAD_TENDER = 'LOAD_TENDER',
    LABEL = 'LABEL',
    PROOF_OF_DELIVERY = 'PROOF_OF_DELIVERY',
    OTHER = 'OTHER',
    TRANSPORT_INVOICE = 'TRANSPORT_INVOICE',
    INVOICE_SUPPORT = 'INVOICE_SUPPORT',
    COMMERCIAL_INVOICE = 'COMMERCIAL_INVOICE',
    CERTIFICATE_OF_INSURANCE = 'CERTIFICATE_OF_INSURANCE',
    AUTHORIZATION_FORM = 'AUTHORIZATION_FORM',
    CERTIFICATE_OF_ORIGIN = 'CERTIFICATE_OF_ORIGIN',
    EXPORT_ACCOMPANYING_DOCUMENT = 'EXPORT_ACCOMPANYING_DOCUMENT',
    EXPORT_LICENSE = 'EXPORT_LICENSE',
    IMPORT_PERMIT = 'IMPORT_PERMIT',
    ONE_TIME_NAFTA = 'ONE_TIME_NAFTA',
    OTHER_CUSTOMS = 'OTHER_CUSTOMS',
    POWER_OF_ATTORNEY = 'POWER_OF_ATTORNEY',
    PACKING_LIST = 'PACKING_LIST',
    SED_DOCUMENT = 'SED_DOCUMENT',
    SHIPPERS_LETTER_OF_INSTRUCTION = 'SHIPPERS_LETTER_OF_INSTRUCTION',
    DECLARATION = 'DECLARATION',
    USMCA_CERTIFICATION_OF_ORIGIN = 'USMCA_CERTIFICATION_OF_ORIGIN',
    USMCA_COMMERCIAL_INVOICE_CERTIFICATION_OF_ORIGIN = 'USMCA_COMMERCIAL_INVOICE_CERTIFICATION_OF_ORIGIN',
    PRO_FORMA_INVOICE = 'PRO_FORMA_INVOICE',
    WAYBILL_DOCUMENT = 'WAYBILL_DOCUMENT',
    TRACKING_CSV = 'TRACKING_CSV',
}

export enum ShipmentDocumentType {
    CORRESPONDENCE_NO_CUSTOMS_VALUE = 'CORRESPONDENCE_NO_CUSTOMS_VALUE',
    ACCOUNTING_DOCUMENTS = 'ACCOUNTING_DOCUMENTS',
    ANALYSIS_REPORTS = 'ANALYSIS_REPORTS',
    APPLICATIONS = 'APPLICATIONS',
    BANK_STATEMENTS = 'BANK_STATEMENTS',
    BID_QUOTATIONS = 'BID_QUOTATIONS',
    BILLS_OF_SALE = 'BILLS_OF_SALE',
    BIRTH_CERTIFICATES = 'BIRTH_CERTIFICATES',
    BONDS = 'BONDS',
    CHECKS = 'CHECKS',
    CLAIM_FILES = 'CLAIM_FILES',
    CLOSING_STATEMENTS = 'CLOSING_STATEMENTS',
    CONFERENCE_REPORTS = 'CONFERENCE_REPORTS',
    CONTRACTS = 'CONTRACTS',
    COST_ESTIMATES = 'COST_ESTIMATES',
    COURT_TRANSCRIPTS = 'COURT_TRANSCRIPTS',
    CREDIT_APPLICATIONS = 'CREDIT_APPLICATIONS',
    DATA_SHEETS = 'DATA_SHEETS',
    DEEDS = 'DEEDS',
    EMPLOYEMENT_PAPERS = 'EMPLOYEMENT_PAPERS',
    ESCROW_INSTRUCTIONS = 'ESCROW_INSTRUCTIONS',
    EXPORT_PAPERS = 'EXPORT_PAPERS',
    FINANCIAL_STATEMENT = 'FINANCIAL_STATEMENT',
    IMMIGRATION_PAPERS = 'IMMIGRATION_PAPERS',
    INCOME_STATEMENTS = 'INCOME_STATEMENTS',
    INSURANCE_DOCUMENTS = 'INSURANCE_DOCUMENTS',
    INTEROFFICE_MEMOS = 'INTEROFFICE_MEMOS',
    INVENTORY_REPORTS = 'INVENTORY_REPORTS',
    INVOICES = 'INVOICES',
    LEASES = 'LEASES',
    LEGAL_DOCUMENTS = 'LEGAL_DOCUMENTS',
    LETTER_OF_CREDIT_PACKETS = 'LETTER_OF_CREDIT_PACKETS',
    LETTERS_AND_CARDS = 'LETTERS_AND_CARDS',
    LOAN_DOCUMENTS = 'LOAN_DOCUMENTS',
    MARRIAGE_CERTIFICATES = 'MARRIAGE_CERTIFICATES',
    MEDICAL_RECORDS = 'MEDICAL_RECORDS',
    OFFICE_RECORDS = 'OFFICE_RECORDS',
    OPERATING_AGREEMENTS = 'OPERATING_AGREEMENTS',
    PARENT_APPLICATIONS = 'PARENT_APPLICATIONS',
    PERMITS = 'PERMITS',
    PHOTOCOPIES = 'PHOTOCOPIES',
    PROPOSALS = 'PROPOSALS',
    PROSPECTUSES = 'PROSPECTUSES',
    PURCHASE_ORDERS = 'PURCHASE_ORDERS',
    QUOTATIONS = 'QUOTATIONS',
    RESERVATION_CONFIRMATION = 'RESERVATION_CONFIRMATION',
    RESUMES = 'RESUMES',
    SALES_AGREEMENTS = 'SALES_AGREEMENTS',
    SALES_REPORTS = 'SALES_REPORTS',
    SHIPPING_DOCUMENTS = 'SHIPPING_DOCUMENTS',
    STATEMENTS_REPORTS = 'STATEMENTS_REPORTS',
    STATISTICAL_DATA = 'STATISTICAL_DATA',
    STOCK_INFORMATION = 'STOCK_INFORMATION',
    TAX_PAPERS = 'TAX_PAPERS',
    TRADE_CONFIRMATION = 'TRADE_CONFIRMATION',
    TRANSCRIPTS = 'TRANSCRIPTS',
    WARRANTY_DEEDS = 'WARRANTY_DEEDS',
    OTHER = 'OTHER',
}

export enum EquipmentType {
    DRYBOX = 'DRYBOX',
    REEFER = 'REEFER',
    //OPENTRAILER, for now we keep FLATBED as equipment type, later will become type of EquipmentType
    FLATBED = 'FLATBED',
    FLATBED_ROLL_TITE = 'FLATBED_ROLL_TITE',
    SPECIALIZED = 'SPECIALIZED',
    OTHER = 'OTHER'
}

export enum InfoMessageSeverity {
    INFO = 'INFO',
    WARNING = 'WARNING',
    ERROR = 'ERROR'
}

export enum OrderStatus {
    PRELOAD = 'PRELOAD',
    GHOST = 'GHOST',
    DRAFT = 'DRAFT',
    NEGOTIATION = 'NEGOTIATION',
    RESERVATION = 'RESERVATION',
    TRANSPORT = 'TRANSPORT',
    BILLING = 'BILLING',
    COMPLETED = 'COMPLETED'
}

export enum OrderInvoiceStatus {
    NOT_INITIATED  = 'NOT_INITIATED',
    INVOICE_PENDING = 'INVOICE_PENDING',
    COMPLETED = 'COMPLETED'
}

export enum OrganizationType {
    CLIENT = 'CLIENT',
    THREE_PL = 'THREE_PL',
    CARRIER = 'CARRIER',
    SYSTEM = 'SYSTEM'
}

export enum NotificationMethod {
    EMAIL = 'EMAIL'
}

// TODO: Completely remove SKID from this enum.
// It currently has to be excluded or filtered when used on the front-end
export enum PackageType {
    PLT = 'PLT',
    BAG = 'BAG',
    BALE = 'BALE',
    BOX = 'BOX',
    BUCKET = 'BUCKET',
    PAIL = 'PAIL',
    BUNDLE = 'BUNDLE',
    CAN = 'CAN',
    CARTON = 'CARTON',
    CASE = 'CASE',
    COIL = 'COIL',
    CRATE = 'CRATE',
    CYLINDER = 'CYLINDER',
    DRUM = 'DRUM',
    PIECES = 'PIECES',
    REEL = 'REEL',
    ROLL = 'ROLL',
    SKID = 'SKID',
    TUBE = 'TUBE',
    TOTE = 'TOTE',
    OTHER = 'OTHER',
    FORKLIFT = 'FORKLIFT'
}

export enum Provider {
    PROJECT_44  = 'PROJECT_44',
    ROSE_ROCKET = 'ROSE_ROCKET',
    GLS_CANADA = 'GLS_CANADA',
    MANITOULIN = 'MANITOULIN',
    THREE_PL = 'THREE_PL',
    PUROLATOR = 'PUROLATOR',
    FEDEX = 'FEDEX',
    NATIONEX = 'NATIONEX',
    CANPAR = 'CANPAR',
    DAY_AND_ROSS = 'DAY_AND_ROSS',
    CANADA_POST = 'CANADA_POST',
    UPS = 'UPS',
    DHL = 'DHL',
    SPEEDY = 'SPEEDY',
    AB_COURIER = 'AB_COURIER',
    MIDLAND = 'MIDLAND',
    MORNEAU = 'MORNEAU'
}

export enum ReservationActionCode {
    //Reservation Accepted---
    A = 'A',
    //Conditional Acceptance---
    B = 'B',
    //Counter Proposal Made---
    C = 'C',
    //Reservation Cancelled---
    D = 'D',
    //New---
    N = 'N',
    //Delete---
    R = 'R',
    //Change---
    U = 'U'
}

export enum RfqStatus {
    // statuses for clients before submission //
    PRELOAD = 'PRELOAD',
    GHOST = 'GHOST',
    DRAFT = 'DRAFT',
    // statuses that define a submission //
    SUBMITTED = 'SUBMITTED',

    // RFQ QUOTE 3PL
    //NOT_STARTED = 'NOT_STARTED',
    // IN_PROGRESS = 'IN_PROGRESS',
    // COMPLETED = 'COMPLETED',
    // NOT_COMPLETED = 'NOT_COMPLETED',
    THREE_PL_QUOTES_AWAITING = 'THREE_PL_QUOTES_AWAITING',
    THREE_PL_QUOTES_PENDING = 'THREE_PL_QUOTES_PENDING',
    THREE_PL_QUOTES_ALL_SUBMITTED = 'THREE_PL_QUOTES_ALL_SUBMITTED',

    // statuses for 3pls that send back to client for approval //
    REVISED = 'REVISED',
    // statuses for clients to accept changes made by the 3pl since they last submitted //
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    // statuses for cancellations before dispatch //
    DECLINED = 'DECLINED',
    CANCELLED = 'CANCELLED',
    ABORTED = 'ABORTED',
    EXPIRED = 'EXPIRED',
    PENDING_CANCELLATION = 'PENDING_CANCELLATION',
    // statuses for 3pls that define a final approval //
    BOOKED = 'BOOKED',
    // statuses for dispatch (creation of a } //
    CARRIER_DISPATCHED = 'CARRIER_DISPATCHED',
    // statuses for non inactive organization waiting to dispatch (creation of a } //
    AWAITING_ORGANIZATION_APPROVAL = 'AWAITING_ORGANIZATION_APPROVAL'
}

export enum ServiceType {
    AUTOMATIC = 'AUTOMATIC',
    INPUT = 'INPUT',
    MANUAL = 'MANUAL',
    EMAIL = 'EMAIL',
    EXTERNAL = 'EXTERNAL',
}

export enum ServiceLevelType {
    EXPRESS = 'Express',
    DEFERRED = 'Deferred',
    GROUND = 'Ground',
    MAIL = 'Mail',
    UNKNOWN = 'Unknown'
}

export enum ShipmentStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE'
}

export enum ShipmentIdentifierSource {
    CUSTOMER = 'CUSTOMER',
    CARRIER = 'CARRIER'
}

export enum ShipmentIdentifierType {
    PICKUP_NUMBER = 'PICKUP_NUMBER',
    PRO_NUMBER = 'PRO_NUMBER',
    BILL_OF_LADING_NUMBER = 'BILL_OF_LADING_NUMBER',
    PURCHASE_ORDER_NUMBER = 'PURCHASE_ORDER_NUMBER',
    TRACKING_NUMBER = 'TRACKING_NUMBER',
    PRO_BILL_NUMBER = 'PRO_BILL_NUMBER',
    CUSTOMER_REFERENCE = 'CUSTOMER_REFERENCE',
    EXTERNAL = 'EXTERNAL',
    OTHER = 'OTHER',
    PIN_NUMBER = 'PIN_NUMBER',
    LOCATION_NUMBER = 'LOCATION_NUMBER',
    REFERENCE = 'REFERENCE',
    MANIFEST_NUMBER = 'MANIFEST_NUMBER',
    CONSIGNOR_REFERENCE = 'CONSIGNOR_REFERENCE',
    FREIGHT_FORWARDER_REFERENCE = 'FREIGHT_FORWARDER_REFERENCE',
    FREIGHT_BILL_NUMBER = 'FREIGHT_BILL_NUMBER',
    INBOUND_CENTER_REFERENCE = 'INBOUND_CENTER_REFERENCE',
    LOAD_LIST_REFERENCE = 'LOAD_LIST_REFERENCE',
    OUTBOUND_CENTER_REFERENCE = 'OUTBOUND_CENTER_REFERENCE',
    LOCAL_PAYOR_ACCOUNT_NUMBER = 'LOCAL_PAYOR_ACCOUNT_NUMBER',
    LOCAL_SHIPPER_ACCOUNT_NUMBER = 'LOCAL_SHIPPER_ACCOUNT_NUMBER',
    LOCAL_RECEIVER_ACCOUNT_NUMBER = 'LOCAL_RECEIVER_ACCOUNT_NUMBER',
    CUSTOMS_DECLARATION_NUMBER = 'CUSTOMS_DECLARATION_NUMBER',
    SHIPMENT_ID = 'SHIPMENT_ID',
}

export enum PickupIdentifierSource {
    CUSTOMER = 'CUSTOMER',
    CARRIER = 'CARRIER'
}

export enum PickupIdentifierType {
    PICKUP_NUMBER = 'PICKUP_NUMBER',
    LOCATION_NUMBER = 'LOCATION_NUMBER',
}

export enum TrackingUpdateExceptionDetail {
    APPOINTMENT_SET = 'APPOINTMENT_SET',
    CANNOT_FIND_CONSIGNEE_ADDRESS = 'CANNOT_FIND_CONSIGNEE_ADDRESS',
    CANNOT_REACH_CONSIGNEE = 'CANNOT_REACH_CONSIGNEE',
    CONS_CLOSED_FOR_HOLIDAY = 'CONS_CLOSED_FOR_HOLIDAY',
    CONS_DID_NOT_ORDER_FREIGHT = 'CONS_DID_NOT_ORDER_FREIGHT',
    CONSIGNEE_CANCELED_ORDER = 'CONSIGNEE_CANCELED_ORDER',
    CONSIGNEE_CLOSED = 'CONSIGNEE_CLOSED',
    CONSIGNEE_DISPUTES_CHARGES = 'CONSIGNEE_DISPUTES_CHARGES',
    CREDIT_HOLD = 'CREDIT_HOLD',
    DAMAGED = 'DAMAGED',
    DAMAGED_ON_DELIVERY = 'DAMAGED_ON_DELIVERY',
    DELIVERY_ATTEMPTED_APPOINTMENT = 'DELIVERY_ATTEMPTED_APPOINTMENT',
    DELIVERY_ATTEMPTED_NO_DOCK_SPACE = 'DELIVERY_ATTEMPTED_NO_DOCK_SPACE',
    DELIVERY_ATTEMPTED_REAR_LOAD = 'DELIVERY_ATTEMPTED_REAR_LOAD',
    DELIVERY_REQUIRES_SPECIAL_EQUIPMENT = 'DELIVERY_REQUIRES_SPECIAL_EQUIPMENT',
    DELIVERY_SHORTAGE = 'DELIVERY_SHORTAGE',
    DUPLICATE_SHIPMENT = 'DUPLICATE_SHIPMENT',
    EMERGENCY_DELAY_IN_TRANSIT = 'EMERGENCY_DELAY_IN_TRANSIT',
    GENERAL_EXCEPTION = 'GENERAL_EXCEPTION',
    HAZARDOUS_MATERIAL = 'HAZARDOUS_MATERIAL',
    INCORRECT_ADDRESS_PROVIDED = 'INCORRECT_ADDRESS_PROVIDED',
    INVALID_OR_MISSING_PURCHASE_ORDER_NUMBER = 'INVALID_OR_MISSING_PURCHASE_ORDER_NUMBER',
    LIMITED_ACCESS_LOCATIONS = 'LIMITED_ACCESS_LOCATIONS',
    MISSING_PACKING_LIST = 'MISSING_PACKING_LIST',
    NO_PAYMENT_AVAILABLE_AT_DELIVERY = 'NO_PAYMENT_AVAILABLE_AT_DELIVERY',
    OVERAGE = 'OVERAGE',
    PERMANENT_APPOINTMENT_REQUIRED = 'PERMANENT_APPOINTMENT_REQUIRED',
    PROTECTIVE_SERVICE_REQUIRED = 'PROTECTIVE_SERVICE_REQUIRED',
    RECONSIGNED_TO_NEW_CONSIGNEE = 'RECONSIGNED_TO_NEW_CONSIGNEE',
    REFUSED_BY_CONS_CANNOT_RECEIVE_ON_DELIVERY_DATE = 'REFUSED_BY_CONS_CANNOT_RECEIVE_ON_DELIVERY_DATE',
    REFUSED_NO_RETURN_AUTHORIZATION = 'REFUSED_NO_RETURN_AUTHORIZATION',
    SHIP_WHEN_CAPACITY_AVAILABLE = 'SHIP_WHEN_CAPACITY_AVAILABLE',
    SHIPMENT_ALASKA_OR_HAWAII = 'SHIPMENT_ALASKA_OR_HAWAII',
    SHIPMENT_TENDERED_LATE = 'SHIPMENT_TENDERED_LATE',
    SHORTAGE = 'SHORTAGE',
    SPOT_MARKET_SHIPMENT = 'SPOT_MARKET_SHIPMENT',
    WAITING_INSTRUCTIONS = 'WAITING_INSTRUCTIONS',
    WEATHER_DELAY = 'WEATHER_DELAY',
    WEATHER_DELAY_CLEARED = 'WEATHER_DELAY_CLEARED',
    WORK_STOPPAGE = 'WORK_STOPPAGE',
    WRONG_PRODUCT_SHIPPED = 'WRONG_PRODUCT_SHIPPED'
}

export enum TrackingUpdateExceptionSummary {
    APPOINTMENT_ISSUE = 'APPOINTMENT_ISSUE',
    BILLING_ISSUE = 'BILLING_ISSUE',
    DELIVERY_ISSUE = 'DELIVERY_ISSUE',
    OTHER_EXCEPTION = 'OTHER_EXCEPTION',
    PAPERWORK_ISSUE = 'PAPERWORK_ISSUE',
    SHIPMENT_ISSUE = 'SHIPMENT_ISSUE',
    SPOT_SHIPMENT = 'SPOT_SHIPMENT',
    STAFFING_ISSUE = 'STAFFING_ISSUE',
    TRANSIT_ISSUE = 'TRANSIT_ISSUE',
    VEHICLE_ISSUE = 'VEHICLE_ISSUE',
    WEATHER_ISSUE = 'WEATHER_ISSUE'
}

export enum TrackingUpdateImageFormat {
    PDF = 'PDF',
    TIF = 'TIF',
    PNG = 'PNG',
    GIF = 'GIF',
    JPG = 'JPG',
    OTHER = 'OTHER',
}

export enum TrackingUpdateImageType {
    BILL_OF_LADING = 'BILL_OF_LADING',
    DELIVERY_RECEIPT = 'DELIVERY_RECEIPT',
    WEIGHT_CERTIFICATE = 'WEIGHT_CERTIFICATE',
    INVOICE = 'INVOICE',
    INSPECTION_CERTIFICATE = 'INSPECTION_CERTIFICATE',
    LETTER_OF_AUTHORIZATION = 'LETTER_OF_AUTHORIZATION',
    LUMPER_CERTIFICATE = 'LUMPER_CERTIFICATE',
    PACKING_SLIP = 'PACKING_SLIP',
    OTHER = 'OTHER'
}

export enum TrackingUpdateStatus {
    CARRIER_DISPATCHED = 'CARRIER_DISPATCHED',
    SCHEDULED_FOR_PICKUP = 'SCHEDULED_FOR_PICKUP',
    PICKUP_ARRIVAL = 'PICKUP_ARRIVAL',
    PICKUP_DEPARTURE = 'PICKUP_DEPARTURE',
    INFO = 'INFO',
    IN_TRANSIT = 'IN_TRANSIT',
    AT_STOP = 'AT_STOP',
    DELAYED_CUSTOMS_HOLD = 'DELAYED_CUSTOMS_HOLD',
    DELAYED_HOLD = 'DELAYED_HOLD',
    DELAYED_OTHER = 'DELAYED_OTHER',
    OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',
    DELIVERY_ARRIVAL = 'DELIVERY_ARRIVAL',
    DELIVERED = 'DELIVERED',
    NOT_DELIVERED = 'NOT_DELIVERED',
    CUSTOMER_PICKUP = 'CUSTOMER_PICKUP',
    RETURN_TO_SENDER = 'RETURN_TO_SENDER',
}

export enum TrackingUpdateStatusReason {
    PICKUP_INFO = 'PICKUP_INFO',
    PICKUP_MISSED = 'PICKUP_MISSED',
    INTERLINE_INFO = 'INTERLINE_INFO',
    INTERLINE_MISSED = 'INTERLINE_MISSED',
    DEPARTURE_INFO = 'DEPARTURE_INFO',
    DEPARTURE_MISSED = 'DEPARTURE_MISSED',
    HELD = 'HELD',
    DELAYED = 'DELAYED',
    DELIVERY_INFO = 'DELIVERY_INFO',
    DELIVERY_MISSED = 'DELIVERY_MISSED',
    DELIVERY_APPOINTMENT = 'DELIVERY_APPOINTMENT',
    DELIVERED = 'DELIVERED',
    EXCEPTION = 'EXCEPTION',
    CANCELLED = 'CANCELLED',
    TIMED_OUT = 'TIMED_OUT'
}

export enum TrackingUpdateStopArrival {
    EN_ROUTE = 'EN_ROUTE',
    ARRIVED = 'ARRIVED',
    DEPARTED = 'DEPARTED',
    UNKNOWN = 'UNKNOWN'
}

export enum TrackingUpdateStopStatus {
    EN_ROUTE = 'EN_ROUTE',
    ARRIVED = 'ARRIVED',
    DEPARTED = 'DEPARTED',
    UNKNOWN = 'UNKNOWN'
}

export enum TrackingUpdateStopType {
    ORIGIN = 'ORIGIN',
    DESTINATION = 'DESTINATION',
    TERMINAL = 'TERMINAL',
    OTHER = 'OTHER'
}

export enum TrackingUpdateType {
    MANUAL = 'MANUAL',
    NORMAL = 'NORMAL',
    STOP = 'STOP'
}

export enum TransportType {
    LTL = 'LTL',
    FTL = 'FTL',
    PARCEL = 'PARCEL',
    ENVELOPE = 'ENVELOPE',
    PAK = 'PAK',
    OTHER = 'OTHER',
}

export enum VolumeUnit {
    FT3 = 'FT3',
    M3 = 'M3'
}

export enum WeightUnit {
    LB = 'LB',
    KG = 'KG'
}

export enum RequestType {
    CUSTOM_QUOTE = 'CUSTOM_QUOTE',
    WITH_COVERAGE = 'WITH_COVERAGE',
    EDI = 'EDI',
}

export enum AccessorialTypeFilter {
    IS_CALL_TAG = 'IS_CALL_TAG',
}

export enum Country {
    AD = 'AD',
    AE = 'AE',
    AF = 'AF',
    AG = 'AG',
    AI = 'AI',
    AL = 'AL',
    AM = 'AM',
    AN = 'AN',
    AO = 'AO',
    AQ = 'AQ',
    AR = 'AR',
    AS = 'AS',
    AT = 'AT',
    AU = 'AU',
    AW = 'AW',
    AX = 'AX',
    AZ = 'AZ',
    BA = 'BA',
    BB = 'BB',
    BD = 'BD',
    BE = 'BE',
    BF = 'BF',
    BG = 'BG',
    BH = 'BH',
    BI = 'BI',
    BJ = 'BJ',
    BL = 'BL',
    BM = 'BM',
    BN = 'BN',
    BO = 'BO',
    BR = 'BR',
    BS = 'BS',
    BT = 'BT',
    BV = 'BV',
    BW = 'BW',
    BY = 'BY',
    BZ = 'BZ',
    CA = 'CA',
    CC = 'CC',
    CD = 'CD',
    CF = 'CF',
    CG = 'CG',
    CH = 'CH',
    CI = 'CI',
    CK = 'CK',
    CL = 'CL',
    CM = 'CM',
    CN = 'CN',
    CO = 'CO',
    CR = 'CR',
    CU = 'CU',
    CV = 'CV',
    CW = 'CW',
    CX = 'CX',
    CY = 'CY',
    CZ = 'CZ',
    DE = 'DE',
    DJ = 'DJ',
    DK = 'DK',
    DM = 'DM',
    DO = 'DO',
    DZ = 'DZ',
    EC = 'EC',
    EE = 'EE',
    EG = 'EG',
    EH = 'EH',
    ER = 'ER',
    ES = 'ES',
    ET = 'ET',
    FI = 'FI',
    FJ = 'FJ',
    FK = 'FK',
    FM = 'FM',
    FO = 'FO',
    FR = 'FR',
    GA = 'GA',
    GB = 'GB',
    GD = 'GD',
    GE = 'GE',
    GF = 'GF',
    GG = 'GG',
    GH = 'GH',
    GI = 'GI',
    GL = 'GL',
    GM = 'GM',
    GN = 'GN',
    GP = 'GP',
    GQ = 'GQ',
    GR = 'GR',
    GS = 'GS',
    GT = 'GT',
    GU = 'GU',
    GW = 'GW',
    GY = 'GY',
    HK = 'HK',
    HM = 'HM',
    HN = 'HN',
    HR = 'HR',
    HT = 'HT',
    HU = 'HU',
    ID = 'ID',
    IE = 'IE',
    IL = 'IL',
    IM = 'IM',
    IN = 'IN',
    IO = 'IO',
    IQ = 'IQ',
    IR = 'IR',
    IS = 'IS',
    IT = 'IT',
    JE = 'JE',
    JM = 'JM',
    JO = 'JO',
    JP = 'JP',
    KE = 'KE',
    KG = 'KG',
    KH = 'KH',
    KI = 'KI',
    KM = 'KM',
    KN = 'KN',
    KP = 'KP',
    KR = 'KR',
    KW = 'KW',
    KY = 'KY',
    KZ = 'KZ',
    LA = 'LA',
    LB = 'LB',
    LC = 'LC',
    LI = 'LI',
    LK = 'LK',
    LR = 'LR',
    LS = 'LS',
    LT = 'LT',
    LU = 'LU',
    LV = 'LV',
    LY = 'LY',
    MA = 'MA',
    MC = 'MC',
    MD = 'MD',
    ME = 'ME',
    MF = 'MF',
    MG = 'MG',
    MH = 'MH',
    MK = 'MK',
    ML = 'ML',
    MM = 'MM',
    MN = 'MN',
    MO = 'MO',
    MP = 'MP',
    MQ = 'MQ',
    MR = 'MR',
    MS = 'MS',
    MT = 'MT',
    MU = 'MU',
    MV = 'MV',
    MW = 'MW',
    MX = 'MX',
    MY = 'MY',
    MZ = 'MZ',
    NA = 'NA',
    NC = 'NC',
    NE = 'NE',
    NF = 'NF',
    NG = 'NG',
    NI = 'NI',
    NL = 'NL',
    NO = 'NO',
    NP = 'NP',
    NR = 'NR',
    NU = 'NU',
    NZ = 'NZ',
    OM = 'OM',
    PA = 'PA',
    PE = 'PE',
    PF = 'PF',
    PG = 'PG',
    PH = 'PH',
    PK = 'PK',
    PL = 'PL',
    PM = 'PM',
    PN = 'PN',
    PR = 'PR',
    PS = 'PS',
    PT = 'PT',
    PW = 'PW',
    PY = 'PY',
    QA = 'QA',
    RE = 'RE',
    RO = 'RO',
    RS = 'RS',
    RU = 'RU',
    RW = 'RW',
    SA = 'SA',
    SB = 'SB',
    SC = 'SC',
    SD = 'SD',
    SE = 'SE',
    SG = 'SG',
    SH = 'SH',
    SI = 'SI',
    SJ = 'SJ',
    SK = 'SK',
    SL = 'SL',
    SM = 'SM',
    SN = 'SN',
    SO = 'SO',
    SR = 'SR',
    SS = 'SS',
    ST = 'ST',
    SV = 'SV',
    SX = 'SX',
    SY = 'SY',
    SZ = 'SZ',
    TC = 'TC',
    TD = 'TD',
    TF = 'TF',
    TG = 'TG',
    TH = 'TH',
    TJ = 'TJ',
    TK = 'TK',
    TL = 'TL',
    TM = 'TM',
    TN = 'TN',
    TO = 'TO',
    TR = 'TR',
    TT = 'TT',
    TV = 'TV',
    TW = 'TW',
    TZ = 'TZ',
    UA = 'UA',
    UG = 'UG',
    US = 'US',
    UY = 'UY',
    UZ = 'UZ',
    VA = 'VA',
    VC = 'VC',
    VE = 'VE',
    VG = 'VG',
    VI = 'VI',
    VN = 'VN',
    VU = 'VU',
    WF = 'WF',
    WS = 'WS',
    XK = 'XK',
    YE = 'YE',
    YT = 'YT',
    ZA = 'ZA',
    ZM = 'ZM',
    ZW = 'ZW',
}

export enum Language {
    EN = 'EN',
    FR = 'FR'
}

export enum Currency {
    CAD = 'CAD',
    USD = 'USD',
}

export enum Timezone {
    EST = 'EST',
    CST = 'CST',
    MST = 'MST',
    PST = 'PST',
    UTC = 'UTC',
    GMT = 'GMT',
    AKST = 'AKST',
    HST = 'HST',
    AST = 'AST',
    NST = 'NST',
    CET = 'CET',
    EET = 'EET',
    IST = 'IST',
    JST = 'JST',
    KST = 'KST',
    AEST = 'AEST',
    AEDT = 'AEDT',
    ACST = 'ACST',
    AWST = 'AWST',
    NZST = 'NZST',
    NZDT = 'NZDT',
    CEST = 'CEST',
    BST = 'BST',
    EDT = 'EDT',
    CDT = 'CDT',
    MDT = 'MDT',
    PDT = 'PDT',
    AKDT = 'AKDT',
    HADT = 'HADT',
    WET = 'WET',
    WEST = 'WEST',
    SGT = 'SGT',
    UZT = 'UZT',
    FJT = 'FJT',
    WAST = 'WAST',
    WAT = 'WAT',
    MSK = 'MSK',
    IRKT = 'IRKT',
    YEKT = 'YEKT',
    BRT = 'BRT',
    CLT = 'CLT',
    ART = 'ART',
    PHT = 'PHT',
    ICT = 'ICT',
    NCT = 'NCT',
    MMT = 'MMT',
    CTT = 'CTT',
}

export enum Locale {
    EN_US = 'en-US',
    EN_CA = 'en-CA',
    FR_CA = 'fr-CA',
}

export enum Gender {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
}

export enum BillingChargeType {
    // Accessorial Charge
    ACC = 'ACC',
    // Additional Charge
    ADDCHRG = 'ADDCHRG',
    // Address Correction
    ADDCOR = 'ADDCOR',
    // After Hours Charge
    AFTRHR = 'AFTRHR',
    // Aviation Insurance Surcharge
    AIS = 'AIS',
    // Weight Fee
    ASWGHT = 'ASWGHT',
    // BC Road Closure
    BCROADCLOSURE = 'BCROADCLOSURE',
    // Border Crossing Fee
    BORDCROSS = 'BORDCROSS',
    // Brokerage Charge
    BRK = 'BRK',
    //Broker Select Option
    BRS = 'BRS',
    // Beyond Charge
    BYC = 'BYC',
    // Capacity surcharge
    CAPACITY = 'CAPACITY',
    // Carbon Tax
    CARBFEE = 'CARBFEE',
    // Committed delivery window
    CDW = 'CDW',
    // Chains
    CHAINS = 'CHNC',
    // Convention/Tradeshow Pickup or Delivery
    CNV = 'CNV',
    // Commercial invoice removal
    COMMINVRMV = 'COMMINVRMV',
    // Construction site
    CON = 'CON',
    // Corrected Bol Fee
    CORRECTEDBOLFEE = 'CORRECTEDBOLFEE',
    // Crane
    CRANE = 'CRNC',
    // Carbon neutral shipment
    CRBNTRL = 'CRBNTRL',
    // Custom inspection
    CUSTINSP = 'CUSTINSP',
    // Detension charge
    DETENTION = 'DETENTION',
    // DDU Oversize
    DDUOVSD = 'DDUOVSD',
    // Deficit Charge
    DEF = 'DEF',
    // Delivery Charge
    DELC = 'DELC',
    // Delivery Confirmation
    DELCONF = 'DELCONF',
    // Dimensional Weight
    DIMWGHT = 'DIMWGHT',
    // Document Fee
    DOCFEE = 'DOCFEE',
    // Dry Ice
    DRYICE = 'DRYICE',
    // Discount
    DSC = 'DSC',
    // Excessive Length Shipment
    ELS = 'ELS',
    // Excess Liability Coverage Charge
    EXLIAB = 'EXLIAB',
    //Extend Area Surcharge
    EXA = 'EXA',
    // Expedite Shipment
    EXPEDITE = 'EXPEDITE',
    // Export License Verification
    EXPLIC = 'EXPLIC',
    // Extra Care Charge
    EXTRACARE = 'XCARE',
    // NL Ferry Surcharge
    FERRY = 'FERRY',
    // Freight
    FREIGHT = 'GFC',
    // Fuel Surcharge
    FUEL_SURCHARGE = 'FSC',
    //Government Charge
    GOV = 'GOV',
    // Delivery before 10h30
    GS10_30 = 'GS10_30',
    // Federal Tax
    GST = 'GST',
    // High Cost Delivery
    HCD = 'HCD',
    // High Cost Pickup
    HCP = 'HCP',
    // High Cost Service Area
    HCSER = 'HCSER',
    // Holiday Pickup
    HDAYPU = 'HDAYPU',
    // HomeLand Security
    HMLAND = 'HMLAND',
    // Harmonized Sales Tax
    HST = 'HST',
    // Interline Shipment
    INT = 'INT',
    // International Special Commodities
    ISC = 'ISC',
    //Kicking Horse Surcharge
    KHS = 'KHS',
    // Item Charge
    ITEM = 'ITEM',
    // Handling and Labeling
    LABEL = 'LABEL',
    // Lazr Coverage
    LAZR_COVERAGE = 'LZRC',
    // Low Commercial Density Charge
    LCDC = 'LCDC',
    // Liftgate Pickup or Delivery
    LGSER = 'LGSER',
    // Linehaul
    LH = 'LH',
    // Large Package
    LRGPCK = 'LRGPCK',
    // Limited Access Pickup or Delivery
    LTDACC = 'LTDACC',
    // Lazr service fee
    LZFEE = 'LZFEE',
    // Lazr share
    LZSHARE = 'LZSHARE',
    // Minimum Charge
    MIN = 'MIN',
    // Nav Canada Fee
    NAVCN = 'NAVCN',
    // Non-contiguous state fee
    NCNTGST = 'NCNTGST',
    // Net Freight Charge
    NFC = 'NFC',
    // Non-conveyable shipment
    NONCONV = 'NONCONV',
    // Non stackable
    NOSTACK = 'NOSTACK',
    // Marine SurCharge
    MARINE = 'MARINE',
    // Ocean Fuel Surcharge
    OFSC = 'OFSC',
    // Other
    OTHER = 'OTHER',
    // Oversize pallet
    OVSP = 'OVSP',
    // Paper Commercial Invoice Surcharge
    PAPERCOMMINV = 'PAPERCOMMINV',
    // Pilot Fatigue Surcharge
    PFS = 'PFS',
    // Print and mail charges
    PNMAIL = 'PNMAIL',
    // Private house
    PRIVATEHOUSE = 'PRIVATEHOUSE',
    // Print return label
    PRLBL = 'PRLBL',
    // Quebec Provincial tax
    PSTQST = 'PSTQST',
    // Pickup Charge
    PUC = 'PUC',
    // Redelivery
    REDELIVERY = 'REDELIVERY',
    // Regional Shipment
    REG = 'REG',
    // Regular Inside del charge on interline point
    RIDCIP = 'RIDCIP',
    // Regular Residential charge on interline point
    RRCOIP = 'RRCOIP',
    // Return Service
    RTNSERV = 'RTNSERV',
    // Rural Charge
    RURAL = 'RURAL',
    // Service area adjustment
    SAADJ = 'SAADJ',
    // Saturday Intl. processing fee
    SATINTL = 'SATINTL',
    // Second Delivery Charge
    SDC = 'SDC',
    // Shipment Delivery Confirmation
    SHIPCONF = 'SHIPCONF',
    // Solutions for Small Business savings
    SSBS = 'SSBS',
    // Storage
    STORAGE = 'STORAGE',
    // Tailgate on Interline
    TAILINT = 'TAILINT',
    // Tarp
    TARP = 'TRPC',
    // Tax
    TAX = 'TAX',
    // Toll
    TOLL = 'TOLL',
    // Total accessorial charge
    TOTALACC = 'TOTALACC',
    // Time Specific Delivery
    TSD = 'TSD',
    // UPS Shipper declaration form
    UPSSEDF = 'UPSSEDF',
    // Overweight surcharge
    WGHT = 'WGHT',
    // Waiting Time
    WAIT = 'WAIT',
    // Weight Inspection
    WEIGHT_INSPECTION = 'WEIGHT_INSPECTION',
    // Shipping charge correction
    SCCO = 'SCCO',
    // Inspection fee
    INSPECTION_FEE = 'INSPECTION_FEE',
    // Reweigh
    REWEIGH = 'REWEIGH',
    // Exchange rate fee
    EXRFEE = 'EXRFEE',
    // Paperless trade
    ETD = 'ETD',
    // Pga Disclaim Fee
    PGADIS = 'PGADIS',
    // Entry Prep Fee
    ENTPREP = 'ENTPREP',
    // Bond Fee
    BONDFEE = 'BONDFEE',
    // Merchandise Processing Fee
    MERPROFEE = 'MERPROFEE',
    // Attempted Pick up Fee
    ATTPUFEE = 'ATTPUFEE',
    // Ferry Increase Recovery
    FIR = 'FIR',
    // Fuel Tax Recovery
    FTR = 'FTR',
    // Geographic Surcharge
    GEOGRAPHIC_SURCHARGE = 'GEOGRAPHIC_SURCHARGE',
    // Express Shipment
    EXSHIP = 'EXSHIP',
    // Standard Service
    STASER = 'STASER',
    // 3 Day Select
    THREEDS = 'THREEDS',
    // Worldwide Express Saver Service
    WEXSA = 'WEXSA',
    // Support Surcharge
    SUSUR = 'SUSUR',
    // Commercial Invoice Paper Surcharge
    CIPS = 'CIPS',
    // Rights and Taxes Options Surcharge
    RITOS = 'RITOS',
    // Missing Shipment Info
    MISIN = 'MISIN',
    // Duty
    DUTY = 'DUTY',
    // Warehouse Fees
    WAHOFEES = 'WAHOFEES',
    //Disbursement Fee
    DSBFEE = 'DSBFEE',
    //Value Added Tax
    VAT = 'VAT',
    //Small Truck Delivery
    STDEL = 'STDEL',
}

export enum EntityType {
    ACCESSORIAL = 'ACCESSORIAL',
    ADDRESS = 'ADDRESS',
    ADDRESS_VALIDATION = 'ADDRESS_VALIDATION',
    AGREEMENT = 'AGREEMENT',
    API_KEY = 'API_KEY',
    CARRIER = 'CARRIER',
    CREDIT_APPLICATION = 'CREDIT_APPLICATION',
    DEFAULT_BILLING_USER_ADDRESS = 'DEFAULT_BILLING_USER_ADDRESS',
    DEFAULT_SHIPPING_USER_ADDRESS = 'DEFAULT_SHIPPING_USER_ADDRESS',
    ORDER = 'ORDER',
    ORDER_DOCUMENT = 'ORDER_DOCUMENT',
    ORDER_HANDLING_UNIT = 'ORDER_HANDLING_UNIT',
    ORDER_QUOTE = 'ORDER_QUOTE',
    ORDER_QUOTE_CHARGE = 'ORDER_QUOTE_CHARGE',
    ORDER_QUOTE_STATUS = 'ORDER_QUOTE_STATUS',
    ORDER_PRICE_REVIEW = 'ORDER_PRICE_REVIEW',
    ORDER_PRICE_REVIEW_CHARGE = 'ORDER_PRICE_REVIEW_CHARGE',
    ORDER_RFQ = 'ORDER_RFQ',
    ORDER_SHIPMENT = 'ORDER_SHIPMENT',
    ORDER_SHIPMENT_IDENTIFIER = 'ORDER_SHIPMENT_IDENTIFIER',
    ORDER_SHIPMENT_TRACKING_UPDATE = 'ORDER_SHIPMENT_TRACKING_UPDATE',
    ORDER_SHIPMENT_TRACKING_UPDATE_IMAGE = 'ORDER_SHIPMENT_TRACKING_UPDATE_IMAGE',
    ORDER_SHIPMENT_TRACKING_UPDATE_STOP = 'ORDER_SHIPMENT_TRACKING_UPDATE_STOP',
    ORGANIZATION = 'ORGANIZATION',
    PROVIDER = 'PROVIDER',
    SEARCH = 'SEARCH',
    SEARCH_RFQ = 'SEARCH_RFQ',
    USER = 'USER',
    CURRENCY_EXCHANGE_RATE = 'CURRENCY_EXCHANGE_RATE',
    PAGE_NAVIGATION = 'PAGE_NAVIGATION',
    ORDER_INVOICE = 'ORDER_INVOICE',
    ORDER_INVOICE_PAYMENT = 'ORDER_INVOICE_PAYMENT',
    ORDER_INVOICE_CSV = 'ORDER_INVOICE_CSV',
    EDI_ACCOUNT = 'EDI_ACCOUNT',
    EDI_ACKNOWLEDGMENT = 'EDI_ACKNOWLEDGMENT',
    EDI_ORDER = 'EDI_ORDER',
    EDI_ORDER_INVOICE = 'EDI_ORDER_INVOICE',
    EDI_ORDER_RESPONSE = 'EDI_ORDER_RESPONSE',
    EDI_ORDER_REVISION = 'EDI_ORDER_REVISION',
    EDI_ORDER_TRACKING_UPDATE = 'EDI_ORDER_TRACKING_UPDATE',
    EDI_QUEUED_MESSAGE = 'EDI_QUEUED_MESSAGE',
    INVOICE_CONSOLIDATION = 'INVOICE_CONSOLIDATION',
    USER_EVENT_NOTIFICATION = 'USER_EVENT_NOTIFICATION',
    STATEMENT_OF_ACCOUNT = 'STATEMENT_OF_ACCOUNT',
    MARKETPLACE_STEP_NAVIGATION = 'MARKETPLACE_STEP_NAVIGATION',
    TRACKING_CSV = 'TRACKING_CSV',
    PICKUP = 'PICKUP',
    QUICKBOOKS_MESSAGE = 'QUICKBOOKS_MESSAGE',
    THREE_PL_ORGANIZATION = 'THREE_PL_ORGANIZATION',
}

export enum EventStatus {
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
}

export enum EventType {
    ADDRESS_ADDED = 'ADDRESS_ADDED',
    ADDRESS_COULD_NOT_BE_ADDED = 'ADDRESS_COULD_NOT_BE_ADDED',
    ADDRESS_COULD_NOT_BE_EDITED = 'ADDRESS_COULD_NOT_BE_EDITED',
    ADDRESS_COULD_NOT_BE_REMOVED = 'ADDRESS_COULD_NOT_BE_REMOVED',
    ADDRESS_EDITED = 'ADDRESS_EDITED',
    ADDRESS_REMOVED = 'ADDRESS_REMOVED',
    ADDRESS_VALIDATOR_MATCH_COULD_NOT_BE_FOUND = 'ADDRESS_VALIDATOR_MATCH_COULD_NOT_BE_FOUND',
    AGREEMENT_ASSIGNED = 'AGREEMENT_ASSIGNED',
    AGREEMENT_ADDED = 'AGREEMENT_ADDED',
    AGREEMENT_EDITED = 'AGREEMENT_EDITED',
    AGREEMENT_DELETED = 'AGREEMENT_DELETED',
    AGREEMENT_DISABLED = 'AGREEMENT_DISABLED',
    AGREEMENT_ENABLED = 'AGREEMENT_ENABLED',
    AGREEMENT_COULD_NOT_BE_ASSIGNED = 'AGREEMENT_COULD_NOT_BE_ASSIGNED',
    AGREEMENT_COULD_NOT_BE_ADDED = 'AGREEMENT_COULD_NOT_BE_ADDED',
    AGREEMENT_COULD_NOT_BE_EDITED = 'AGREEMENT_COULD_NOT_BE_EDITED',
    AGREEMENT_COULD_NOT_BE_DELETED = 'AGREEMENT_COULD_NOT_BE_DELETED',
    AGREEMENT_COULD_NOT_BE_DISABLED = 'AGREEMENT_COULD_NOT_BE_DISABLED',
    AGREEMENT_COULD_NOT_BE_ENABLED = 'AGREEMENT_COULD_NOT_BE_ENABLED',
    API_KEY_COULD_NOT_BE_DISABLED = 'API_KEY_COULD_NOT_BE_DISABLED',
    API_KEY_COULD_NOT_BE_ENABLED = 'API_KEY_COULD_NOT_BE_ENABLED',
    API_KEY_COULD_NOT_BE_GENERATED = 'API_KEY_COULD_NOT_BE_GENERATED',
    API_KEY_COULD_NOT_BE_REMOVED = 'API_KEY_COULD_NOT_BE_REMOVED',
    API_KEY_DISABLED = 'API_KEY_DISABLED',
    API_KEY_ENABLED = 'API_KEY_ENABLED',
    API_KEY_GENERATED = 'API_KEY_GENERATED',
    API_KEY_REMOVED = 'API_KEY_REMOVED',
    CREDIT_APPLICATION_SUBMITTED = 'CREDIT_APPLICATION_SUBMITTED',
    CREDIT_APPLICATION_SCORE_UPDATED = 'CREDIT_APPLICATION_SCORE_UPDATED',
    CREDIT_APPLICATION_COULD_NOT_BE_SUBMITTED = 'CREDIT_APPLICATION_COULD_NOT_BE_SUBMITTED',
    CREDIT_APPLICATION_SCORE_COULD_NOT_BE_UPDATED = 'CREDIT_APPLICATION_SCORE_COULD_NOT_BE_UPDATED',
    CURRENCY_EXCHANGE_RATE_CHANGED = 'CURRENCY_EXCHANGE_RATE_CHANGED',
    CURRENCY_EXCHANGE_RATE_COULD_NOT_BE_CHANGED = 'CURRENCY_EXCHANGE_RATE_COULD_NOT_BE_CHANGED',
    DEFAULT_BILLING_USER_ADDRESS_ADDED = 'DEFAULT_BILLING_USER_ADDRESS_ADDED',
    DEFAULT_BILLING_USER_ADDRESS_COULD_NOT_BE_ADDED = 'DEFAULT_BILLING_USER_ADDRESS_COULD_NOT_BE_ADDED',
    DEFAULT_BILLING_USER_ADDRESS_REMOVED = 'DEFAULT_BILLING_USER_ADDRESS_REMOVED',
    DEFAULT_BILLING_USER_ADDRESS_COULD_NOT_BE_REMOVED = 'DEFAULT_BILLING_USER_ADDRESS_COULD_NOT_BE_REMOVED',
    DEFAULT_SHIPPING_USER_ADDRESS_ADDED = 'DEFAULT_SHIPPING_USER_ADDRESS_ADDED',
    DEFAULT_SHIPPING_USER_ADDRESS_COULD_NOT_BE_ADDED = 'DEFAULT_SHIPPING_USER_ADDRESS_COULD_NOT_BE_ADDED',
    DEFAULT_SHIPPING_USER_ADDRESS_REMOVED = 'DEFAULT_SHIPPING_USER_ADDRESS_REMOVED',
    DEFAULT_SHIPPING_USER_ADDRESS_COULD_NOT_BE_REMOVED = 'DEFAULT_SHIPPING_USER_ADDRESS_COULD_NOT_BE_REMOVED',
    INVOICE_CONSOLIDATION_COULD_NOT_BE_ADDED = 'INVOICE_CONSOLIDATION_COULD_NOT_BE_ADDED',
    INVOICE_CONSOLIDATION_ADDED = 'INVOICE_CONSOLIDATION_ADDED',
    INVOICE_CONSOLIDATION_GENERATED = 'INVOICE_CONSOLIDATION_GENERATED',
    INVOICE_CONSOLIDATION_COULD_NOT_BE_GENERATED = 'INVOICE_CONSOLIDATION_COULD_NOT_BE_GENERATED',
    INVOICE_CONSOLIDATION_SENT = 'INVOICE_CONSOLIDATION_SENT',
    INVOICE_CONSOLIDATION_COULD_NOT_BE_SENT = 'INVOICE_CONSOLIDATION_COULD_NOT_BE_SENT',
    INVOICE_CONSOLIDATION_RESENT = 'INVOICE_CONSOLIDATION_RESENT',
    INVOICE_CONSOLIDATION_COULD_NOT_BE_RESENT = 'INVOICE_CONSOLIDATION_COULD_NOT_BE_RESENT',
    ORDER_ABORTED = 'ORDER_ABORTED',
    ORDER_REACTIVATED = 'ORDER_REACTIVATED',
    ORDER_ACCEPTED = 'ORDER_ACCEPTED',
    ORDER_BILLING_ADDRESS_CHANGED = 'ORDER_BILLING_ADDRESS_CHANGED',
    ORDER_BILLING_ADDRESS_COULD_NOT_BE_CHANGED = 'ORDER_BILLING_ADDRESS_COULD_NOT_BE_CHANGED',
    ORDER_BILLING_CURRENCY_CHANGED = 'ORDER_BILLING_CURRENCY_CHANGED',
    ORDER_BILLING_CURRENCY_COULD_NOT_BE_CHANGED = 'ORDER_BILLING_CURRENCY_COULD_NOT_BE_CHANGED',
    ORDER_BILLING_PO_CHANGED = 'ORDER_BILLING_PO_CHANGED',
    ORDER_BILLING_PO_COULD_NOT_BE_CHANGED  = 'ORDER_BILLING_PO_COULD_NOT_BE_CHANGED',
    ORDER_BILL_OF_LADING_ADDED = 'ORDER_BILL_OF_LADING_ADDED',
    ORDER_BILL_OF_LADING_COULD_NOT_BE_ADDED = 'ORDER_BILL_OF_LADING_COULD_NOT_BE_ADDED',
    ORDER_BILL_OF_LADING_COULD_NOT_BE_GENERATED = 'ORDER_BILL_OF_LADING_COULD_NOT_BE_GENERATED',
    ORDER_BILL_OF_LADING_GENERATED = 'ORDER_BILL_OF_LADING_GENERATED',
    ORDER_LABEL_ADDED = 'ORDER_LABEL_ADDED',
    ORDER_LABEL_COULD_NOT_BE_ADDED = 'ORDER_LABEL_COULD_NOT_BE_ADDED',
    ORDER_LABEL_COULD_NOT_BE_GENERATED = 'ORDER_LABEL_COULD_NOT_BE_GENERATED',
    ORDER_LABEL_GENERATED = 'ORDER_LABEL_GENERATED',
    ORDER_LOAD_TENDER_ADDED = 'ORDER_LOAD_TENDER_ADDED',
    ORDER_LOAD_TENDER_COULD_NOT_BE_ADDED = 'ORDER_LOAD_TENDER_COULD_NOT_BE_ADDED',
    ORDER_LOAD_TENDER_COULD_NOT_BE_GENERATED = 'ORDER_LOAD_TENDER_COULD_NOT_BE_GENERATED',
    ORDER_LOAD_TENDER_GENERATED = 'ORDER_LOAD_TENDER_GENERATED',
    ORDER_COMMERCIAL_INVOICE_ADDED = 'ORDER_COMMERCIAL_INVOICE_ADDED',
    ORDER_COMMERCIAL_INVOICE_COULD_NOT_BE_ADDED = 'ORDER_COMMERCIAL_INVOICE_COULD_NOT_BE_ADDED',
    ORDER_COMMERCIAL_INVOICE_COULD_NOT_BE_GENERATED = 'ORDER_COMMERCIAL_INVOICE_COULD_NOT_BE_GENERATED',
    ORDER_COMMERCIAL_INVOICE_GENERATED = 'ORDER_COMMERCIAL_INVOICE_GENERATED',
    ORDER_CERTIFICATE_OF_INSURANCE_ADDED = 'ORDER_CERTIFICATE_OF_INSURANCE_ADDED',
    ORDER_CERTIFICATE_OF_INSURANCE_COULD_NOT_BE_ADDED = 'ORDER_CERTIFICATE_OF_INSURANCE_COULD_NOT_BE_ADDED',
    ORDER_CERTIFICATE_OF_INSURANCE_COULD_NOT_BE_GENERATED = 'ORDER_CERTIFICATE_OF_INSURANCE_COULD_NOT_BE_GENERATED',
    ORDER_CERTIFICATE_OF_INSURANCE_GENERATED = 'ORDER_CERTIFICATE_OF_INSURANCE_GENERATED',
    ORDER_BOOKED = 'ORDER_BOOKED',
    ORDER_CANCELLED = 'ORDER_CANCELLED',
    ORDER_THREE_PL_CHANGED = 'ORDER_THREE_PL_CHANGED',
    ORDER_THREE_PL_COULD_NOT_BE_CHANGED = 'ORDER_THREE_PL_COULD_NOT_BE_CHANGED',
    ORDER_CLIENT_COMPLETED = 'ORDER_CLIENT_COMPLETED',
    ORDER_THREE_PL_COMPLETED = 'ORDER_THREE_PL_COMPLETED',
    ORDER_THREE_PL_AGENT_ASSIGNED = 'ORDER_THREE_PL_AGENT_ASSIGNED',
    ORDER_THREE_PL_AGENT_COULD_NOT_BE_ASSIGNED = 'ORDER_THREE_PL_AGENT_COULD_NOT_BE_ASSIGNED',
    ORDER_CLOSED = 'ORDER_CLOSED',
    ORDER_COULD_NOT_BE_ABORTED = 'ORDER_COULD_NOT_BE_ABORTED',
    ORDER_COULD_NOT_BE_REACTIVATED = 'ORDER_COULD_NOT_BE_REACTIVATED',
    ORDER_COULD_NOT_BE_ACCEPTED = 'ORDER_COULD_NOT_BE_ACCEPTED',
    ORDER_COULD_NOT_BE_BOOKED = 'ORDER_COULD_NOT_BE_BOOKED',
    ORDER_COULD_NOT_BE_CANCELLED = 'ORDER_COULD_NOT_BE_CANCELLED',
    ORDER_COULD_NOT_BE_CLOSED = 'ORDER_COULD_NOT_BE_CLOSED',
    ORDER_COULD_NOT_BE_DECLINED = 'ORDER_COULD_NOT_BE_DECLINED',
    ORDER_COULD_NOT_BE_DISPATCHED = 'ORDER_COULD_NOT_BE_DISPATCHED',
    ORDER_COULD_NOT_BE_DRAFTED = 'ORDER_COULD_NOT_BE_DRAFTED',
    ORDER_COULD_NOT_BE_EDITED = 'ORDER_COULD_NOT_BE_EDITED',
    ORDER_COULD_NOT_BE_EXPIRED = 'ORDER_COULD_NOT_BE_EXPIRED',
    ORDER_COULD_NOT_BE_INVOICED = 'ORDER_COULD_NOT_BE_INVOICED',
    ORDER_COULD_NOT_BE_MARKED_AS_REVISED = 'ORDER_COULD_NOT_BE_MARKED_AS_REVISED',
    ORDER_COULD_NOT_BE_OPENED = 'ORDER_COULD_NOT_BE_OPENED',
    ORDER_PRICE_COULD_NOT_BE_REVIEWED = 'ORDER_PRICE_COULD_NOT_BE_REVIEWED',
    ORDER_COULD_NOT_BE_REJECTED = 'ORDER_COULD_NOT_BE_REJECTED',
    ORDER_COULD_NOT_BE_SUBMITTED = 'ORDER_COULD_NOT_BE_SUBMITTED',
    ORDER_CUSTOM_QUOTE_ADDED = 'ORDER_CUSTOM_QUOTE_ADDED',
    ORDER_CUSTOM_QUOTE_COULD_NOT_BE_ADDED = 'ORDER_CUSTOM_QUOTE_COULD_NOT_BE_ADDED',
    ORDER_QUOTE_DELETED = 'ORDER_QUOTE_DELETED',
    ORDER_QUOTE_COULD_NOT_BE_DELETED = 'ORDER_QUOTE_COULD_NOT_BE_DELETED',
    ORDER_QUOTE_EDITED = 'ORDER_QUOTE_EDITED',
    ORDER_DECLINED = 'ORDER_DECLINED',
    ORDER_DISPATCHED = 'ORDER_DISPATCHED',
    ORDER_DOCUMENT_ADDED = 'ORDER_DOCUMENT_ADDED',
    ORDER_DOCUMENT_COULD_NOT_BE_ADDED = 'ORDER_DOCUMENT_COULD_NOT_BE_ADDED',
    ORDER_DOCUMENT_COULD_NOT_BE_FETCHED = 'ORDER_DOCUMENT_COULD_NOT_BE_FETCHED',
    ORDER_DOCUMENT_COULD_NOT_BE_REMOVED = 'ORDER_DOCUMENT_COULD_NOT_BE_REMOVED',
    ORDER_DOCUMENT_FETCHED = 'ORDER_DOCUMENT_FETCHED',
    ORDER_DOCUMENT_REMOVED = 'ORDER_DOCUMENT_REMOVED',
    ORDER_DRAFTED = 'ORDER_DRAFTED',
    ORDER_DRAFT_COULD_NOT_BE_SUBMITTED = 'ORDER_DRAFT_COULD_NOT_BE_SUBMITTED',
    ORDER_DRAFT_SUBMITTED = 'ORDER_DRAFT_SUBMITTED',
    ORDER_EDITED = 'ORDER_EDITED',
    ORDER_EXPIRED = 'ORDER_EXPIRED',
    ORDER_INVOICED = 'ORDER_INVOICED',
    ORDER_MARKED_AS_REVISED = 'ORDER_MARKED_AS_REVISED',
    ORDER_OPENED = 'ORDER_OPENED',
    ORDER_PRIVATE_NOTES_COULD_NOT_BE_SAVED = 'ORDER_PRIVATE_NOTES_COULD_NOT_BE_SAVED',
    ORDER_PRIVATE_NOTES_SAVED = 'ORDER_PRIVATE_NOTES_SAVED',
    ORDER_PROOF_OF_DELIVERY_ADDED = 'ORDER_PROOF_OF_DELIVERY_ADDED',
    ORDER_TRANSPORT_INVOICE_DOCUMENT_ADDED = 'ORDER_TRANSPORT_INVOICE_DOCUMENT_ADDED',
    ORDER_INVOICE_SUPPORT_DOCUMENT_ADDED = 'ORDER_INVOICE_SUPPORT_DOCUMENT_ADDED',
    ORDER_PROOF_OF_DELIVERY_COULD_NOT_BE_ADDED = 'ORDER_PROOF_OF_DELIVERY_COULD_NOT_BE_ADDED',
    ORDER_TRANSPORT_INVOICE_DOCUMENT_COULD_NOT_BE_ADDED = 'ORDER_TRANSPORT_INVOICE_DOCUMENT_COULD_NOT_BE_ADDED',
    ORDER_INVOICE_SUPPORT_DOCUMENT_COULD_NOT_BE_ADDED = 'ORDER_INVOICE_SUPPORT_DOCUMENT_COULD_NOT_BE_ADDED',
    ORDER_INVOICE_CSV_BATCH_ADDED = 'ORDER_INVOICE_CSV_BATCH_ADDED',
    ORDER_INVOICE_CSV_BATCH_COULD_NOT_BE_ADDED = 'ORDER_INVOICE_CSV_BATCH_COULD_NOT_BE_ADDED',
    ORDER_INVOICE_CSV_BATCH_EDITED = 'ORDER_INVOICE_CSV_BATCH_EDITED',
    ORDER_INVOICE_CSV_BATCH_COULD_NOT_BE_EDITED = 'ORDER_INVOICE_CSV_BATCH_COULD_NOT_BE_EDITED',
    ORDER_INVOICE_CSV_BATCH_APPROVED = 'ORDER_INVOICE_CSV_BATCH_APPROVED',
    ORDER_INVOICE_CSV_BATCH_REJECTED = 'ORDER_INVOICE_CSV_BATCH_REJECTED',
    ORDER_INVOICE_CSV_BATCH_COULD_NOT_BE_APPROVED = 'ORDER_INVOICE_CSV_BATCH_COULD_NOT_BE_APPROVED',
    ORDER_INVOICE_CSV_BATCH_COULD_NOT_BE_REJECTED = 'ORDER_INVOICE_CSV_BATCH_COULD_NOT_BE_REJECTED',
    ORDER_INVOICE_CSV_BATCH_PROCESSED = 'ORDER_INVOICE_CSV_BATCH_PROCESSED',
    ORDER_INVOICE_CSV_BATCH_COULD_NOT_BE_PROCESSED = 'ORDER_INVOICE_CSV_BATCH_COULD_NOT_BE_PROCESSED',
    ORDER_QUOTE_COULD_NOT_BE_SELECTED = 'ORDER_QUOTE_COULD_NOT_BE_SELECTED',
    ORDER_QUOTE_SELECTED = 'ORDER_QUOTE_SELECTED',
    ORDER_PENDING_PRICE_REVIEW_EDITED = 'ORDER_PENDING_PRICE_REVIEW_EDITED',
    ORDER_PENDING_PRICE_REVIEW_COULD_NOT_BE_EDITED = 'ORDER_PENDING_PRICE_REVIEW_COULD_NOT_BE_EDITED',
    ORDER_PENDING_PRICE_REVIEW_COULD_NOT_BE_REJECTED = 'ORDER_PENDING_PRICE_REVIEW_COULD_NOT_BE_REJECTED',
    ORDER_PENDING_PRICE_REVIEW_REJECTED = 'ORDER_PENDING_PRICE_REVIEW_REJECTED',
    ORDER_PRICE_REVIEWED = 'ORDER_PRICE_REVIEWED',
    ORDER_QUOTE_CHANGED = 'ORDER_QUOTE_CHANGED',
    ORDER_QUOTE_COULD_NOT_BE_CHANGED = 'ORDER_QUOTE_COULD_NOT_BE_CHANGED',
    ORDER_RFQ_QUOTE_ADDED = 'ORDER_RFQ_QUOTE_ADDED',
    ORDER_RFQ_QUOTE_COULD_NOT_BE_ADDED = 'ORDER_RFQ_QUOTE_COULD_NOT_BE_ADDED',
    ORDER_RFQ_QUOTE_DELETED = 'ORDER_RFQ_QUOTE_DELETED',
    ORDER_RFQ_QUOTE_COULD_NOT_BE_DELETED = 'ORDER_RFQ_QUOTE_COULD_NOT_BE_DELETED',
    ORDER_RFQ_QUOTE_STATUS_ADDED = 'ORDER_RFQ_QUOTE_STATUS_ADDED',
    ORDER_RFQ_QUOTE_STATUS_COULD_NOT_BE_ADDED = 'ORDER_RFQ_QUOTE_STATUS_COULD_NOT_BE_ADDED',
    ORDER_RFQ_QUOTE_STATUS_DELETED = 'ORDER_RFQ_QUOTE_STATUS_DELETED',
    ORDER_RFQ_QUOTE_STATUS_COULD_NOT_BE_DELETED = 'ORDER_RFQ_QUOTE_STATUS_COULD_NOT_BE_DELETED',
    ORDER_REJECTED = 'ORDER_REJECTED',
    ORDER_RFQ_STATUS_CHANGED = 'ORDER_RFQ_STATUS_CHANGED',
    ORDER_RFQ_STATUS_COULD_NOT_BE_CHANGED = 'ORDER_RFQ_STATUS_COULD_NOT_BE_CHANGED',
    ORDER_RFQ_COVERAGE_CHANGED = 'ORDER_RFQ_COVERAGE_CHANGED',
    ORDER_RFQ_COVERAGE_COULD_NOT_BE_CHANGED = 'ORDER_RFQ_COVERAGE_COULD_NOT_BE_CHANGED',
    ORGANIZATION_SETTINGS_CHANGED = 'ORGANIZATION_SETTINGS_CHANGED',
    ORGANIZATION_SETTINGS_COULD_NOT_BE_CHANGED = 'ORGANIZATION_SETTINGS_COULD_NOT_BE_CHANGED',
    ORDER_SHIPMENT_CARRIER_NUMBER_COULD_NOT_BE_SET = 'ORDER_SHIPMENT_CARRIER_NUMBER_COULD_NOT_BE_SET',
    ORDER_SHIPMENT_CARRIER_NUMBER_SET = 'ORDER_SHIPMENT_CARRIER_NUMBER_SET',
    ORDER_SHIPMENT_ESTIMATED_DELIVERY_DATE_COULD_NOT_BE_SET = 'ORDER_SHIPMENT_ESTIMATED_DELIVERY_DATE_COULD_NOT_BE_SET',
    ORDER_SHIPMENT_ESTIMATED_DELIVERY_DATE_SET = 'ORDER_SHIPMENT_ESTIMATED_DELIVERY_DATE_SET',
    ORDER_SHIPMENT_TRACKING_COULD_NOT_BE_ENGAGED = 'ORDER_SHIPMENT_TRACKING_COULD_NOT_BE_ENGAGED',
    ORDER_SHIPMENT_TRACKING_ENGAGED = 'ORDER_SHIPMENT_TRACKING_ENGAGED',
    ORDER_SHIPMENT_TRACKING_UPDATE_ADDED = 'ORDER_SHIPMENT_TRACKING_UPDATE_ADDED',
    ORDER_SHIPMENT_TRACKING_UPDATE_COULD_NOT_BE_ADDED = 'ORDER_SHIPMENT_TRACKING_UPDATE_COULD_NOT_BE_ADDED',
    ORDER_SHIPMENT_TRACKING_UPDATE_COULD_NOT_BE_REMOVED = 'ORDER_SHIPMENT_TRACKING_UPDATE_COULD_NOT_BE_REMOVED',
    ORDER_SHIPMENT_TRACKING_UPDATE_REMOVED = 'ORDER_SHIPMENT_TRACKING_UPDATE_REMOVED',
    ORDER_STATUS_CHANGED = 'ORDER_STATUS_CHANGED',
    ORDER_STATUS_COULD_NOT_BE_CHANGED = 'ORDER_STATUS_COULD_NOT_BE_CHANGED',
    ORDER_SUBMITTED = 'ORDER_SUBMITTED',
    ORGANIZATION_APPROVED = 'ORGANIZATION_APPROVED',
    ORGANIZATION_COULD_NOT_BE_APPROVED = 'ORGANIZATION_COULD_NOT_BE_APPROVED',
    ORGANIZATION_COULD_NOT_BE_DEACTIVATED = 'ORGANIZATION_COULD_NOT_BE_DEACTIVATED',
    ORGANIZATION_COULD_NOT_BE_BLOCKED = 'ORGANIZATION_COULD_NOT_BE_BLOCKED',
    ORGANIZATION_COULD_NOT_BE_EVALUATED = 'ORGANIZATION_COULD_NOT_BE_EVALUATED',
    ORGANIZATION_COULD_NOT_BE_DELETED = 'ORGANIZATION_COULD_NOT_BE_DELETED',
    ORGANIZATION_COULD_NOT_BE_REGISTERED = 'ORGANIZATION_COULD_NOT_BE_REGISTERED',
    ORGANIZATION_DEACTIVATED = 'ORGANIZATION_DEACTIVATED',
    ORGANIZATION_BLOCKED = 'ORGANIZATION_BLOCKED',
    ORGANIZATION_EVALUATED = 'ORGANIZATION_EVALUATED',
    ORGANIZATION_DELETED = 'ORGANIZATION_DELETED',
    ORGANIZATION_REGISTERED = 'ORGANIZATION_REGISTERED',
    PAGE_NAVIGATION_COULD_NOT_BE_RECORDED = 'PAGE_NAVIGATION_COULD_NOT_BE_RECORDED',
    PAGE_NAVIGATION_RECORDED = 'PAGE_NAVIGATION_RECORDED',
    PREPARE_SEARCH_COULD_NOT_BE_UPDATED = 'PREPARE_SEARCH_COULD_NOT_BE_UPDATED',
    PREPARE_SEARCH_UPDATED = 'PREPARE_SEARCH_UPDATED',
    PREPARE_SEARCH_COULD_NOT_BE_CREATED = 'PREPARE_SEARCH_COULD_NOT_BE_CREATED',
    PREPARE_SEARCH_CREATED = 'PREPARE_SEARCH_CREATED',
    PICKUP_SCHEDULED = 'PICKUP_SCHEDULED',
    PICKUP_COULD_NOT_BE_SCHEDULED = 'PICKUP_COULD_NOT_BE_SCHEDULED',
    PICKUP_STATUS_UPDATED = 'PICKUP_STATUS_UPDATED',
    PICKUP_STATUS_COULD_NOT_BE_UPDATED = 'PICKUP_STATUS_COULD_NOT_BE_UPDATED',
    QUICKBOOKS_MESSAGE_ADDED = 'QUICKBOOKS_MESSAGE_ADDED',
    QUICKBOOKS_MESSAGE_COULD_NOT_BE_ADDED = 'QUICKBOOKS_MESSAGE_COULD_NOT_BE_ADDED',
    SEARCH_COULD_NOT_BE_CREATED = 'SEARCH_COULD_NOT_BE_CREATED',
    SEARCH_CREATED = 'SEARCH_CREATED',
    SEARCH_RFQ_PROGRESS_UPDATED = 'SEARCH_RFQ_PROGRESS_UPDATED',
    USER_ACTIVATED = 'USER_ACTIVATED',
    USER_AUTHENTICATED = 'USER_AUTHENTICATED',
    USER_AVATAR_CHANGED = 'USER_AVATAR_CHANGED',
    USER_AVATAR_COULD_NOT_BE_CHANGED = 'USER_AVATAR_COULD_NOT_BE_CHANGED',
    USER_CONFIRMATION_CODE_COULD_NOT_BE_RESENT = 'USER_CONFIRMATION_CODE_COULD_NOT_BE_RESENT',
    USER_CONFIRMATION_CODE_RESENT = 'USER_CONFIRMATION_CODE_RESENT',
    USER_COULD_NOT_BE_ACTIVATED = 'USER_COULD_NOT_BE_ACTIVATED',
    USER_COULD_NOT_BE_AUTHENTICATED = 'USER_COULD_NOT_BE_AUTHENTICATED',
    USER_COULD_NOT_BE_DEACTIVATED = 'USER_COULD_NOT_BE_DEACTIVATED',
    USER_COULD_NOT_BE_DELETED = 'USER_COULD_NOT_BE_DELETED',
    USER_COULD_NOT_BE_INVITED = 'USER_COULD_NOT_BE_INVITED',
    USER_COULD_NOT_BE_LOGGED_OUT = 'USER_COULD_NOT_BE_LOGGED_OUT',
    USER_COULD_NOT_BE_REGISTERED = 'USER_COULD_NOT_BE_REGISTERED',
    USER_COULD_NOT_BE_PURGED = 'USER_COULD_NOT_BE_PURGED',
    USER_DEACTIVATED = 'USER_DEACTIVATED',
    USER_DELETED = 'USER_DELETED',
    USER_PURGED = 'USER_PURGED',
    USER_EVENT_NOTIFICATION_ADDED = 'USER_EVENT_NOTIFICATION_ADDED',
    USER_EVENT_NOTIFICATION_MARKED_AS_READ = 'USER_EVENT_NOTIFICATION_MARKED_AS_READ',
    USER_EVENT_NOTIFICATIONS_ALL_MARKED_AS_READ = 'USER_EVENT_NOTIFICATIONS_ALL_MARKED_AS_READ',
    USER_EVENT_NOTIFICATIONS_ALL_MARKED_AS_VIEWED = 'USER_EVENT_NOTIFICATIONS_ALL_MARKED_AS_VIEWED',
    USER_EVENT_NOTIFICATION_COULD_NOT_BE_MARKED_AS_READ = 'USER_EVENT_NOTIFICATION_COULD_NOT_BE_MARKED_AS_READ',
    USER_EVENT_NOTIFICATIONS_COULD_NOT_BE_ALL_MARKED_AS_READ = 'USER_EVENT_NOTIFICATIONS_COULD_NOT_BE_ALL_MARKED_AS_READ',
    USER_EVENT_NOTIFICATIONS_COULD_NOT_BE_ALL_MARKED_AS_VIEWED = 'USER_EVENT_NOTIFICATIONS_COULD_NOT_BE_ALL_MARKED_AS_VIEWED',
    USER_INVITED = 'USER_INVITED',
    USER_LOGGED_OUT = 'USER_LOGGED_OUT',
    USER_PASSWORD_CHANGED = 'USER_PASSWORD_CHANGED',
    USER_PASSWORD_CHANGE_COULD_NOT_BE_REQUESTED = 'USER_PASSWORD_CHANGE_COULD_NOT_BE_REQUESTED',
    USER_PASSWORD_CHANGE_REQUESTED = 'USER_PASSWORD_CHANGE_REQUESTED',
    USER_PASSWORD_COULD_NOT_BE_CHANGED = 'USER_PASSWORD_COULD_NOT_BE_CHANGED',
    USER_PROFILE_COULD_NOT_BE_EDITED = 'USER_PROFILE_COULD_NOT_BE_EDITED',
    USER_PROFILE_EDITED = 'USER_PROFILE_EDITED',
    ORGANIZATION_COULD_NOT_BE_EDITED = 'ORGANIZATION_COULD_NOT_BE_EDITED',
    ORGANIZATION_EDITED = 'ORGANIZATION_EDITED',
    USER_REGISTERED = 'USER_REGISTERED',
    USER_SIGN_UP_CONFIRMED = 'USER_SIGN_UP_CONFIRMED',
    USER_SIGN_UP_COULD_NOT_BE_CONFIRMED = 'USER_SIGN_UP_COULD_NOT_BE_CONFIRMED',
    EDI_ORDER_ACCEPTED = 'EDI_ORDER_ACCEPTED',
    EDI_ORDER_ADDED = 'EDI_ORDER_ADDED',
    EDI_ORDER_CANCELLED = 'EDI_ORDER_CANCELLED',
    EDI_ORDER_DECLINED = 'EDI_ORDER_DECLINED',
    EDI_ORDER_EXPIRED = 'EDI_ORDER_EXPIRED',
    EDI_ORDER_INVOICE_ADDED = 'EDI_ORDER_INVOICE_ADDED',
    EDI_ORDER_REVISION_ADDED = 'EDI_ORDER_REVISION_ADDED',
    EDI_ORDER_TRACKING_UPDATE_ADDED = 'EDI_ORDER_TRACKING_UPDATE_ADDED',
    EDI_ORDER_TRANSFERRED = 'EDI_ORDER_TRANSFERRED',
    ORDER_SHIPMENT_AND_PICKUP_COULD_NOT_BE_CANCELLED = 'ORDER_SHIPMENT_AND_PICKUP_COULD_NOT_BE_CANCELLED',
    ORDER_SHIPMENT_AND_PICKUP_CANCELLED = 'ORDER_SHIPMENT_AND_PICKUP_CANCELLED',
    ORDER_INVOICE_STATUS_CHANGED = 'ORDER_INVOICE_STATUS_CHANGED',
    ORDER_INVOICE_STATUS_COULD_NOT_BE_CHANGED = 'ORDER_INVOICE_STATUS_COULD_NOT_BE_CHANGED',
    ORDER_INVOICE_EDITED = 'ORDER_INVOICE_EDITED',
    ORDER_INVOICE_COULD_NOT_BE_EDITED = 'ORDER_INVOICE_COULD_NOT_BE_EDITED',
    ORDER_INVOICE_PAYMENT_ADDED = 'ORDER_INVOICE_PAYMENT_ADDED',
    ORDER_INVOICE_SENT = 'ORDER_INVOICE_SENT',
    ORDER_INVOICE_COULD_NOT_BE_SENT = 'ORDER_INVOICE_COULD_NOT_BE_SENT',
    ORDER_INVOICE_GENERATED = 'ORDER_INVOICE_GENERATED',
    ORDER_INVOICE_COULD_NOT_BE_GENERATED = 'ORDER_INVOICE_COULD_NOT_BE_GENERATED',
    INVOICE_EMAIL_SENT = 'INVOICE_EMAIL_SENT',
    INVOICE_EMAIL_SENT_FAILED = 'INVOICE_EMAIL_SENT_FAILED',
    INVOICE_CONSOLIDATION_EMAIL_SENT = 'INVOICE_CONSOLIDATION_EMAIL_SENT',
    INVOICE_CONSOLIDATION_EMAIL_SENT_FAILED = 'INVOICE_CONSOLIDATION_EMAIL_SENT_FAILED',
    STATEMENT_OF_ACCOUNT_GENERATED = 'STATEMENT_OF_ACCOUNT_GENERATED',
    STATEMENT_OF_ACCOUNT_COULD_NOT_BE_GENERATED = 'STATEMENT_OF_ACCOUNT_COULD_NOT_BE_GENERATED',
    STATEMENT_OF_ACCOUNT_SENT = 'STATEMENT_OF_ACCOUNT_SENT',
    STATEMENT_OF_ACCOUNT_COULD_NOT_BE_SENT = 'STATEMENT_OF_ACCOUNT_COULD_NOT_BE_SENT',
    ORDER_INVOICE_RESENT = 'ORDER_INVOICE_RESENT',
    ORDER_INVOICE_COULD_NOT_BE_RESENT = 'ORDER_INVOICE_COULD_NOT_BE_RESENT',
    MARKETPLACE_STEP_NAVIGATION_RECORDED = 'MARKETPLACE_STEP_NAVIGATION_RECORDED',
    MARKETPLACE_STEP_NAVIGATION_COULD_NOT_BE_RECORDED = 'MARKETPLACE_STEP_NAVIGATION_COULD_NOT_BE_RECORDED',
    INVOICE_VOIDED = 'INVOICE_VOIDED',
    INVOICE_DELETED = 'INVOICE_DELETED',
    INVOICE_VOIDED_FAILED = 'INVOICE_VOIDED_FAILED',
    INVOICE_DELETED_FAILED = 'INVOICE_DELETED_FAILED',
    ORGANIZATION_PERMISSIONS_CHANGED = 'ORGANIZATION_PERMISSIONS_CHANGED',
    ORGANIZATION_PERMISSIONS_COULD_NOT_BE_CHANGED = 'ORGANIZATION_PERMISSIONS_COULD_NOT_BE_CHANGED',
    TRACKING_CSV_BATCH_ADDED = 'TRACKING_CSV_BATCH_ADDED',
    TRACKING_CSV_BATCH_COULD_NOT_BE_ADDED = 'TRACKING_CSV_BATCH_COULD_NOT_BE_ADDED',
    TRACKING_CSV_DOCUMENT_ADDED = 'TRACKING_CSV_DOCUMENT_ADDED',
    TRACKING_CSV_DOCUMENT_COULD_NOT_BE_ADDED = 'TRACKING_CSV_DOCUMENT_COULD_NOT_BE_ADDED',
    PRICE_REVIEW_DELETED = 'PRICE_REVIEW_DELETED',
    PRICE_REVIEW_DELETED_FAILED = 'PRICE_REVIEW_DELETED_FAILED',
    BATCH_SHIPMENT_STARTED = 'BATCH_SHIPMENT_STARTED',
    BATCH_SHIPMENT_PROCESSED = 'BATCH_SHIPMENT_PROCESSED',
    ADDRESS_VALIDATION = 'ADDRESS_VALIDATION',
    ORGANIZATION_THREE_PL_ASSIGNED = 'ORGANIZATION_THREE_PL_ASSIGNED',
    ORGANIZATION_THREE_PL_UNASSIGNED = 'ORGANIZATION_THREE_PL_UNASSIGNED',
    ORGANIZATION_THREE_PL_COULD_NOT_ASSIGNED = 'ORGANIZATION_THREE_PL_COULD_NOT_ASSIGNED',
    ORGANIZATION_THREE_PL_COULD_NOT_UNASSIGNED = 'ORGANIZATION_THREE_PL_COULD_NOT_UNASSIGNED',
}

export enum DatabaseTable {
    ACCESSORIAL = 'accessorial',
    ACCESSORIAL_LOCATION_TYPE = 'accessorial_location_type',
    ACCESSORIAL_PROVIDER = 'accessorial_provider',
    ACCESSORIAL_TRANSPORT_TYPE = 'accessorial_transport_type',
    ACCESSORIAL_TYPE = 'accessorial_type',
    ADDRESS = 'address',
    ADDRESS_ACCESSORIAL = 'address_accessorial',
    ADDRESS_TYPE = 'address_type',
    AFFILIATE_LINK = 'affiliate_link',
    AGREEMENT = 'agreement',
    AGREEMENT_MAPPING = 'agreement_mapping',
    API_KEY = 'api_key',
    BILLING_CHARGE_TYPE = 'billing_charge_type',
    BILL_DUTIES_TO_PARTY = 'bill_duties_to_party',
    CARRIER = 'carrier',
    CARRIER_INFO = 'carrier_info',
    CARRIER_INFO_CONTACT = 'carrier_info_contact',
    CARRIER_SERVICE = 'carrier_service',
    CARRIER_TYPE = 'carrier_type',
    COUNTRY = 'country',
    COVERAGE_COMMODITY_TYPE = 'coverage_commodity_type',
    CURRENCY = 'currency',
    CURRENCY_EXCHANGE_RATE = 'currency_exchange_rate',
    CUSTOMS_BROKER = 'customs_broker',
    DENSITY_UNIT = 'density_unit',
    DIMENSION_UNIT = 'dimension_unit',
    SUBMIT_STATUS = 'submit_status',
    DOCUMENT = 'document',
    DOCUMENT_TYPE = 'document_type',
    EDI_ACCOUNT = 'edi_account',
    EDI_ACKNOWLEDGMENT = 'edi_acknowledgment',
    EDI_ORDER = 'edi_order',
    EDI_ORDER_INVOICE = 'edi_order_invoice',
    EDI_ORDER_RESPONSE = 'edi_order_response',
    EDI_ORDER_REVISION = 'edi_order_revision',
    EDI_ORDER_STATUS = 'edi_order_status',
    EDI_ORDER_TRACKING_UPDATE = 'edi_order_tracking_update',
    EDI_QUEUE = 'edi_queue',
    EQUIPMENT_TYPE = 'equipment_type',
    EVENT = 'event',
    EVENT_STATUS = 'event_status',
    EVENT_TYPE = 'event_type',
    GENDER = 'gender',
    HANDLING_UNIT = 'handling_unit',
    HANDLING_UNIT_ACCESSORIAL = 'handling_unit_accessorial',
    CUSTOMS_DETAIL = 'customs_detail',
    HANDLING_UNIT_CUSTOMS_DETAIL = 'handling_unit_customs_detail',
    HAZMAT_CLASS = 'hazmat_class',
    HAZMAT_MODE = 'hazmat_mode',
    INCOTERMS_TYPE = 'incoterms_type',
    IMPORT_EXPORT_TYPE = 'import_export_type',
    INFO_MESSAGE_SEVERITY = 'info_message_severity',
    INVOICE = 'invoice',
    INVOICE_CHARGE = 'invoice_charge',
    INVOICE_CONSOLIDATION = 'invoice_consolidation',
    INVOICE_CSV_BATCH = 'invoice_csv_batch',
    INVOICE_CSV_BATCH_ERROR = 'invoice_csv_batch_error',
    INVOICE_PAYMENT = 'invoice_payment',
    INVOICE_RECIPIENT = 'invoice_recipient',
    INVOICE_CONSOLIDATION_RECIPIENT = 'invoice_consolidation_recipient',
    INVOICE_TAX = 'invoice_tax',
    LABEL_FORMAT_TYPE = 'label_format_type',
    LABEL_SIZE_TYPE = 'label_size_type',
    LANGUAGE = 'language',
    LOCALE = 'locale',
    LOG_DISPATCH = 'log_dispatch',
    LOG_SEARCH = 'log_search',
    NON_DELIVERY_HANDLING_TYPE = 'non_delivery_handling_type',
    NOTIFICATION_METHOD = 'notification_method',
    NOTIFICATION_SETTING = 'notification_setting',
    ORDER = 'order',
    ORDERV2 = 'order_v2',
    ORDER_STATUS = 'order_status',
    ORGANIZATION = 'organization',
    ORGANIZATION_STATUS = 'organization_status',
    ORGANIZATION_TYPE = 'organization_type',
    ORGANIZATION_PROVIDER_ACCOUNT = 'organization_provider_account',
    ORGANIZATION_GOOGLE_ADS_TRACKING = 'organization_google_ads_tracking',
    PENDING_PRICE_REVIEW = 'pending_price_review',
    ORGANIZATION_PROVIDER_ACCOUNT_STATUS = 'organization_provider_account_status',
    PENDING_PRICE_REVIEW_CHARGE = 'pending_price_review_charge',
    PACKAGE_TYPE = 'package_type',
    PROVIDER = 'provider',
    QUICKBOOKS_CREDENTIAL = 'quickbooks_credential',
    UPS_CREDENTIAL = 'ups_credential',
    QUOTE = 'quote',
    RFQ_CLIENT_THREEPL_ASSIGNED = 'rfq_client_threepl_assigned',
    RFQ_CLIENT_THREEPL_ASSIGNED_STATUS = 'rfq_client_threepl_assigned_status',
    REASON = 'reason',
    QUICKBOOKS_MESSAGE = 'quickbooks_message',
    QUOTE_CHARGE = 'quote_charge',
    PRICE_REVIEW = 'price_review',
    PRICE_REVIEW_CHARGE = 'price_review_charge',
    REASON_FOR_EXPORT_TYPE = 'reason_for_export_type',
    DEMO_REQUEST = 'demo_request',
    RFQ = 'rfq',
    RFQ_ACCESSORIAL = 'rfq_accessorial',
    RFQ_STATUS = 'rfq_status',
    SERVICE_LEVEL = 'service_level',
    SERVICE_TYPE = 'service_type',
    SHIPMENT = 'shipment',
    SHIPMENT_IDENTIFIER = 'shipment_identifier',
    SHIPMENT_IDENTIFIER_SOURCE = 'shipment_identifier_source',
    SHIPMENT_IDENTIFIER_TYPE = 'shipment_identifier_type',
    SHIPMENT_INFO_MESSAGE = 'shipment_info_message',
    SHIPMENT_STATUS = 'shipment_status',
    SHIPMENT_STATUS_CODE = 'shipment_status_code',
    TIMEZONE = 'timezone',
    TRACKING_UPDATE = 'tracking_update',
    TRACKING_UPDATE_EXCEPTION_DETAIL = 'tracking_update_exception_detail',
    TRACKING_UPDATE_EXCEPTION_SUMMARY = 'tracking_update_exception_summary',
    TRACKING_UPDATE_IMAGE = 'tracking_update_image',
    TRACKING_UPDATE_IMAGE_FORMAT = 'tracking_update_image_format',
    TRACKING_UPDATE_IMAGE_TYPE = 'tracking_update_image_type',
    TRACKING_UPDATE_INFO_MESSAGE = 'tracking_update_info_message',
    TRACKING_UPDATE_STATUS = 'tracking_update_status',
    TRACKING_UPDATE_STATUS_REASON = 'tracking_update_status_reason',
    TRACKING_UPDATE_STOP = 'tracking_update_stop',
    TRACKING_UPDATE_STOP_ARRIVAL = 'tracking_update_stop_arrival',
    TRACKING_UPDATE_STOP_STATUS = 'tracking_update_stop_status',
    TRACKING_UPDATE_STOP_TYPE = 'tracking_update_stop_type',
    TRACKING_UPDATE_TYPE = 'tracking_update_type',
    TRACKING_UPDATE_CSV_BATCH = 'tracking_update_csv_batch',
    TRACKING_UPDATE_CSV_BATCH_ERROR = 'tracking_update_csv_batch_error',
    TRACKING_UPDATE_CSV_BATCH_PROCESSED_ROW = 'tracking_update_csv_batch_processed_row',
    TRANSPORT_TYPE = 'transport_type',
    ACTIVE_USER = 'active_user',
    DELETED_USER = 'deleted_user',
    USER = 'user',
    USER_EVENT_NOTIFICATION = 'user_event_notification',
    USER_ORGANIZATION = 'user_organization',
    USER_STATUS = 'user_status',
    USER_TITLE = 'user_title',
    VOLUME_UNIT = 'volume_unit',
    WEBSOCKET_SUBSCRIPTION = 'websocket_subscription',
    WEIGHT_UNIT = 'weight_unit',
    XERO_CREDENTIAL = 'xero_credential',
    XERO_CONTACT = 'xero_contact',
    QUICKBOOKS_CONTACT = 'quickbooks_contact',
    TARIFF_TYPE = 'tariff_type',
    COFACE_STATUS = 'coface_status',
    ORGANIZATION_PERMISSION = 'organization_permission',
    ORGANIZATION_ROLE = 'organization_role',
    COLLECT = 'collect',
    LANE = 'lane',
    PAYMENT_TYPE_OPTION = 'payment_type_option',
    COLLECT_ACCOUNT = 'collect_account',
    COLLECT_ACCOUNT_TYPE = 'collect_account_type',
    BILL_TO_ADDRESS = 'bill_to_address',
    DEFAULT_BILLING_USER_ADDRESS = 'default_billing_user_address',
    DEFAULT_SHIPPING_USER_ADDRESS = 'default_shipping_user_address',
    PICKUP = 'pickup',
    PICKUP_UNIT = 'pickup_unit',
    PICKUP_IDENTIFIER = 'pickup_identifier',
    PICKUP_IDENTIFIER_SOURCE = 'pickup_identifier_source',
    PICKUP_IDENTIFIER_TYPE = 'pickup_identifier_type',

    CARRIER_PROVIDER = 'carrier_provider',
    B13A_FILING_OPTION = 'b13a_filing_option',
    EXPORT_COMPLIANCE_STATEMENT = 'export_compliance_statement',
    SHIPPING_MODE = 'shipping_mode',
    LANE_TYPE = 'lane_type',
    ACCOUNT_MANAGER = 'account_manager',
    ORDER_BATCH = 'order_batch',
    QUOTE_COVERAGE_TYPE = 'quote_coverage_type',
    WAREHOUSE='warehouse',
    ORGANIZATION_THREE_PL ='organization_three_pl',
    RETURN_ORDER_REASON = 'return_order_reason',
    THREE_PL_REGION ='three_pl_region',
    THREE_PL_FREIGHT_SERVICE ='three_pl_freight_service',
    THREE_PL_MANAGEMENT ='three_pl_management',
    API_KEY_REQUEST_LOG='api_key_request_log',
    ORGANIZATION_THREE_PL_STATUS = 'organization_three_pl_status',
}

export enum UserTitle {
    MR = 'Mr',
    MRS = 'Mrs',
    MS = 'Ms',
    MISS = 'Miss',
    MX = 'Mx'
}

export enum PageName {
    HOME = 'HOME',
    ORDER_LIST = 'ORDER_LIST',
    ORDER_DETAILS = 'ORDER_DETAILS',
    PUBLIC_TRACKING = 'PUBLIC_TRACKING',
    PROFILE = 'PROFILE',
    PAGE_NOT_FOUND = 'PAGE_NOT_FOUND',
    UNAUTHORIZED = 'UNAUTHORIZED',
    INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
    ADDRESS_BOOK = 'ADDRESS_BOOK',
    NEW_ADDRESS = 'NEW_ADDRESS',
    EDIT_ADDRESS = 'EDIT_ADDRESS',
    NEW_ORDER = 'NEW_ORDER',
    NEW_CONSOLIDATION = 'NEW_CONSOLIDATION',
    MARKETPLACE = 'MARKETPLACE',
    ORGANIZATION_LIST = 'ORGANIZATION_LIST',
    USER_LIST = 'USER_LIST',
    INVOICE_LIST = 'INVOICE_LIST',
    INVOICE_PREVIEW = 'INVOICE_PREVIEW',
    EDI_ORDER_LIST = 'EDI_ORDER_LIST',
    USER_EVENT_NOTIFICATION_LIST = 'USER_EVENT_NOTIFICATION_LIST',
    EDI_ORDER_DETAILS = 'EDI_ORDER_DETAILS',
    SYSTEM_DASHBOARD = 'SYSTEM_DASHBOARD',
    STATEMENT_OF_ACCOUNT = 'STATEMENT_OF_ACCOUNT',
    INVOICE_CSV_BATCH = 'INVOICE_CSV_BATCH',
    INVOICE_CSV_BATCH_DETALIS = 'INVOICE_CSV_BATCH_DETALIS',
    LANDING = 'LANDING',
    TRACKING_UPDATE_CSV_BATCH = 'TRACKING_UPDATE_CSV_BATCH',
}

export enum ImportExportType {
    PERMANENT = 'PERMANENT',
    TEMPORARY = 'TEMPORARY',
    REPAIR = 'REPAIR',
    RETURN = 'RETURN',
    COMMERCIAL = 'COMMERCIAL',
    SAMPLE = 'SAMPLE',
    INTER_COMPANY = 'INTER_COMPANY',
    RETURN_FOR_REPAIR = 'RETURN_FOR_REPAIR',
    RETURN_AFTER_REPAIR = 'RETURN_AFTER_REPAIR',
    PERSONAL_EFFECTS = 'PERSONAL_EFFECTS',
    PERSONAL_USE = 'PERSONAL_USE',
    CATALOG_ORDER_RETURN = 'CATALOG_ORDER_RETURN',
    TRADE_SHOW = 'TRADE_SHOW',
    DEMO_PROTOTYPE = 'DEMO_PROTOTYPE',
    TEMPORARY_EXPORT = 'TEMPORARY_EXPORT',
    RETURN_TO_MANUFACTURER = 'RETURN_TO_MANUFACTURER',
    GIFT = 'GIFT',
    OTHER = 'OTHER',
    REMARKS = 'REMARKS',
    PERMANENT_IMPORT_EXPORT = 'PERMANENT_IMPORT_EXPORT',
    RETURN_WARRANTY_EXCHANGE = 'RETURN_WARRANTY_EXCHANGE',
}

export enum BillDutiesToParty {
    SENDER = 'SENDER',
    RECEIVER = 'RECEIVER',
    THIRD_PARTY = 'THIRD_PARTY',
}

export enum HazmatClass {
    FULLYREGULATED = 'FULLYREGULATED',
    UN3373 = 'UN3373',
    UN1845 = 'UN1845',
    LESSTHAN500KGEXEMPT = 'LESSTHAN500KGEXEMPT',
    LIMITEDQUANTITIES = 'LIMITEDQUANTITIES',
}

export enum HazmatMode {
    AIR = 'AIR',
    GROUND = 'GROUND',
}

export enum HazmatAccessibiltyType {
    ACCESSIBLE = 'ACCESSIBLE',
    INACCESSIBLE = 'INACCESSIBLE',
}

export enum InvoiceStatus {
    PENDING = 'PENDING',
    PARTIALLY_PAID = 'PARTIALLY_PAID',
    PAID = 'PAID',
    REVISION_REQUESTED = 'REVISION_REQUESTED',
    DELETED = 'DELETED',
    VOIDED = 'VOIDED',
}
export enum InvoiceType {
    RECEIVABLE = 'RECEIVABLE',
    PAYABLE = 'PAYABLE',
    EXTERNAL = 'EXTERNAL'
}

export enum ConsolidationType {
    RECEIVABLE = 'RECEIVABLE',
    PAYABLE = 'PAYABLE',
}

export enum QuoteType {
    SKID = 'SKID',
    CWT = 'CWT',
}

export enum SubmitStatus {
    NOT_STARTED = 'NOT_STARTED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    NOT_COMPLETED = 'NOT_COMPLETED',
    THREE_PL_QUOTES_AWAITING = 'THREE_PL_QUOTES_AWAITING',
    THREE_PL_QUOTES_PENDING = 'THREE_PL_QUOTES_PENDING',
    THREE_PL_QUOTES_ALL_SUBMITTED = 'THREE_PL_QUOTES_ALL_SUBMITTED',
}

export enum UserStatus {
    ACTIVE = 'ACTIVE',
    VERIFIED = 'VERIFIED',
    INACTIVE = 'INACTIVE',
    UNVERIFIED = 'UNVERIFIED'
}

export enum UserStatusAction {
    ACTIVATE = 'Activate',
    DEACTIVATE = 'Deactivate',
    DELETE = 'Delete'
}

export enum OrganizationStatus {
    APPROVED = 'APPROVED',
    INACTIVE = 'INACTIVE',
    LIMITED = 'LIMITED',
    EVALUATING = 'EVALUATING',
    BLOCKED = 'BLOCKED',
}

export enum CofaceStatus {
    APPROVED = 'APPROVED',
    REFUSED = 'REFUSED',
}

export enum OrganizationStatusAction {
    APPROVE = 'Approve',
    EVALUATE = 'Evaluate',
    DEACTIVATE = 'Deactivate',
    DELETE = 'Delete',
    BLOCK = 'Block',
}

export enum UserActivityStatus {
    ONLINE = 'Online',
    OFFLINE = 'Offline',
    AWAY = 'Away',
    NEVER = 'Never',
}

export enum USMCA {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E'
}

export enum InvoiceCategory {
    INVOICE = 'INVOICE',
    CREDIT_NOTE = 'CREDIT_NOTE'
}

export enum TaxType {
    GST = 'GST',
    HST_NB = 'HST_NB',
    HST_NL = 'HST_NL',
    HST_NS = 'HST_NS',
    HST_ON = 'HST_ON',
    HST_PE = 'HST_PE',
    NO_TAX = 'NO_TAX',
    QST_GST = 'QST_GST',
}

export enum ContactType {
    BILLING = 'BILLING',
    SALES = 'SALES',
    DISPATCH = 'DISPATCH',
    INFO = 'INFO'
}

export enum TariffType {
    CLIENT = 'CLIENT',
    '3PL' = '3PL',
    LAZR = 'LAZR',
    CLIENT_TARIFF_NO_TRANSPORT_COST = 'CLIENT_TARIFF_NO_TRANSPORT_COST',
    COLLECT = 'COLLECT',
}

export enum BillingTransportType {
    LTL = 'LTL',
    FTL = 'FTL',
    MAILING = 'Mailing',
    OTHER = 'Other',
}

export enum BillingPreference {
    INDIVIDUAL_INVOICES = 'INDIVIDUAL_INVOICES',
    CONSOLIDATED_INVOICES = 'CONSOLIDATED_INVOICES'
}

export enum BillingStatus {
    PENDING_PRICE_REVIEW = 'PENDING_PRICE_REVIEW',
    PENDING_INVOICE = 'PENDING_INVOICE',
    AWAITING_PAYMENT = 'AWAITING_PAYMENT',
    PENDING_PAYMENT = 'PENDING_PAYMENT',
    PAYMENT_PAST_DUE = 'PAYMENT_PAST_DUE',
}

export enum InvoiceEmailSendingStatus {
    NOT_SENT = 'NOT_SENT',
    SENT = 'SENT',
    SENT_FAILED = 'SENT_FAILED',
}

export enum ReasonForExportType {
    DOC = 'DOC',                      // document
    SAM = 'SAM',                      // commercial sample
    REP = 'REP',                      // repair or warranty
    SOG = 'SOG',                      // sale of goods
    OTH = 'OTH',                      // other
}

export enum NonDeliveryHandlingType {
    RASE = 'RASE', // Return at Sender’s Expense
    RTS = 'RTS',   // Return to Sender
    ABAN = 'ABAN', // Abandon
}

export enum UserRole {
    ADMIN= 'ADMIN',
    AGENT= 'AGENT',
    SHIPPER= 'SHIPPER',
    ACCOUNTANT= 'ACCOUNTANT',
    SUPPLIER= 'SUPPLIER',
}

export enum MessageServiceRequestType {
    ORDER_EXPIRATION_NOTICE = 'ORDER_EXPIRATION_NOTICE',
    LOAD_TENDER_DISPATCH = 'LOAD_TENDER_DISPATCH'
}
export enum InvoiceCsvStatus {
    PENDING = 'PENDING',
    PROCESSED = 'PROCESSED',
    ERROR = 'ERROR',
    REJECTED = 'REJECTED',
}

export enum TrackingCsvStatus {
    PROCESSED = 'PROCESSED',
    ERROR = 'ERROR',
}

export enum PendingPriceReviewStatus {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
}

export enum IncotermsType {
    CIF = 'CIF',
    CIP = 'CIP',
    CPT= 'CPT',
    CFR = 'CFR',
    DAP = 'DAP',
    DDP = 'DDP',
    DDU = 'DDU',
    DEQ = 'DEQ',
    DES = 'DES',
    DPU = 'DPU',
    EXW = 'EXW',
    FAS = 'FAS',
    FCA = 'FCA',
    FOB = 'FOB',
    DAT = 'DAT',
    DAF = 'DAF',
}

export enum MarketplaceStepName {
    SHIPMENT_INFO = 'Shipment info',
    MARKETPLACE = 'Marketplace',
    COMPLETE_ORDER = 'Complete order',
    CONFIRMATION = 'Confirmation',
}

export enum LabelFormatType {
    PDF='PDF',
    ZPL='ZPL'
}

export enum LabelSizeType {
    FOUR_BY_SIX = '4x6',
    EIGHT_BY_ELEVEN = '8x11',
}

export enum QuickBooksLineDetailTypeEnum {
    SALES_ITEM_LINE_DETAIL = 'SalesItemLineDetail',
    GROUP_LINE_DETAIL = 'GroupLineDetail',
    TAX_LINE_DETAIL = 'TaxLineDetail',
    ACCOUNT_BASED_EXPENSE_LINE_DETAIL = 'AccountBasedExpenseLineDetail',
    ITEM_BASED_EXPENSE_LINE_DETAIL = 'ItemBasedExpenseLineDetail',
}

export enum QuickBooksGlobalTaxCalculationEnum {
    TAX_EXCLUDED = 'TaxExcluded',
    TAX_INCLUSIVE = 'TaxInclusive',
    NOT_APPLICABLE = 'NotApplicable',
}

export enum QuickBooksEmailStatusEnum {
    NOT_SET = 'NotSet',
    NEED_TO_SEND = 'NeedToSend',
    EMAIL_SENT = 'EmailSent'
}

export enum QuickBooksCustomFieldTypeEnum {
    STRING_TYPE = 'StringType',
    BOOLEAN_TYPE ='BooleanType'
}

export enum QuickBooksDeliveryTypeEnum {
    EMAIL = 'Email'
}

export enum QuickBooksBillableStatusEnum {
    BILLABLE = 'Billable',
    NOT_BILLABLE = 'NotBillable',
    HAS_BEEN_BILLED = 'HasBeenBilled',
}

export enum QuickBooksCCPaymentStatusEnum {
    COMPLETED = 'Completed',
    UNKNOWN = 'Unknown',
}

export enum InvoicePaymentType {
    PAYMENT = 'PAYMENT',
    BILL_PAYMENT = 'BILL_PAYMENT'
}

export enum QuickBooksAccountType {
    BANK = 'Bank',
    OTHER_CURRENT_ASSET = 'Other Current Asset',
    FIXED_ASSET = 'Fixed Asset',
    OTHER_ASSET = 'Other Asset',
    ACCOUNTS_RECEIVABLE = 'Accounts Receivable',
    EQUITY = 'Equity',
    EXPENSE = 'Expense',
    OTHER_EXPENSE = 'Other Expense',
    COST_OF_GOODS_SOLD = 'Cost of Goods Sold',
    ACCOUNTS_PAYABLE = 'Accounts Payable',
    CREDIT_CARD = 'Credit Card',
    LONG_TERM_LIABILITY = 'Long Term Liability',
    OTHER_CURRENT_LIABILITY = 'Other Current Liability',
    INCOME = 'Income',
    OTHER_INCOME = 'Other Income',
}

export enum QuickBooksAccountSubType {
    // Bank
    CASH_ON_HAND = 'CashOnHand', // (default)
    CHECKING = 'Checking',
    MONEY_MARKET = 'MoneyMarket',
    RENTS_HELD_IN_TRUST = 'RentsHeldInTrust',
    SAVINGS = 'Savings',
    TRUST_ACCOUNTS = 'TrustAccounts',
    CASH_AND_CASH_EQUIVALENTS = 'CashAndCashEquivalents',
    OTHER_EAR_MARKED_BANK_ACCOUNTS = 'OtherEarMarkedBankAccounts',

    // Other Current Asset
    ALLOWANCE_FOR_BAD_DEBTS = 'AllowanceForBadDebts',
    DEVELOPMENT_COSTS = 'DevelopmentCosts',
    EMPLOYEE_CASH_ADVANCES = 'EmployeeCashAdvances',    // (default)
    OTHER_CURRENT_ASSETS = 'OtherCurrentAssets',
    INVENTORY = 'Inventory',
    INVESTMENT_MORTGAGE_REAL_ESTATE_LOANS = 'Investment_MortgageRealEstateLoans',
    INVESTMENT_OTHER = 'Investment_Other',
    INVESTMENT_TAX_EXEMPT_SECURITIES = 'Investment_TaxExemptSecurities',
    INVESTMENT_US_GOVERNMENT_OBLIGATIONS = 'Investment_USGovernmentObligations',
    LOANS_TO_OFFICERS = 'LoansToOfficers',
    LOANS_TO_OTHERS = 'LoansToOthers',
    LOANS_TO_STOCKHOLDERS = 'LoansToStockholders',
    PREPAID_EXPENSES = 'PrepaidExpenses',
    RETAINAGE = 'Retainage',
    UNDEPOSITED_FUNDS = 'UndepositedFunds',
    ASSETS_AVAILABLE_FOR_SALE = 'AssetsAvailableForSale',
    BAL_WITH_GOVT_AUTHORITIES = 'BalWithGovtAuthorities',
    CALLED_UP_SHARE_CAPITAL_NOT_PAID = 'CalledUpShareCapitalNotPaid',
    EXPENDITURE_AUTHORISATIONS_AND_LETTERS_OF_CREDIT = 'ExpenditureAuthorisationsAndLettersOfCredit',
    GLOBAL_TAX_DEFERRED = 'GlobalTaxDeferred',
    GLOBAL_TAX_REFUND = 'GlobalTaxRefund',
    INTERNAL_TRANSFERS = 'InternalTransfers',
    OTHER_CONSUMABLES = 'OtherConsumables',
    PROVISIONS_CURRENT_ASSETS = 'ProvisionsCurrentAssets',
    SHORT_TERM_INVESTMENTS_IN_RELATED_PARTIES = 'ShortTermInvestmentsInRelatedParties',
    SHORT_TERM_LOANS_AND_ADVANCES_TO_RELATED_PARTIES = 'ShortTermLoansAndAdvancesToRelatedParties',
    TRADE_AND_OTHER_RECEIVABLES = 'TradeAndOtherReceivables',

    // Fixed Asset
    ACCUMULATED_DEPLETION = 'AccumulatedDepletion',
    ACCUMULATED_DEPRECIATION = 'AccumulatedDepreciation',
    DEPLETABLE_ASSETS = 'DepletableAssets',
    FIXED_ASSET_COMPUTERS = 'FixedAssetComputers',
    FIXED_ASSET_COPIERS = 'FixedAssetCopiers',
    FIXED_ASSET_FURNITURE = 'FixedAssetFurniture',
    FIXED_ASSET_PHONE = 'FixedAssetPhone',
    FIXED_ASSET_PHOTO_VIDEO = 'FixedAssetPhotoVideo',
    FIXED_ASSET_SOFTWARE = 'FixedAssetSoftware',
    FIXED_ASSET_OTHER_TOOLS_EQUIPMENT = 'FixedAssetOtherToolsEquipment',
    FURNITURE_AND_FIXTURES = 'FurnitureAndFixtures',    // (default)
    LAND = 'Land',
    LEASEHOLD_IMPROVEMENTS = 'LeaseholdImprovements',
    OTHER_FIXED_ASSETS = 'OtherFixedAssets',
    ACCUMULATED_AMORTIZATION = 'AccumulatedAmortization',
    BUILDINGS = 'Buildings',
    INTANGIBLE_ASSETS = 'IntangibleAssets',
    MACHINERY_AND_EQUIPMENT = 'MachineryAndEquipment',
    VEHICLES = 'Vehicles',
    ASSETS_IN_COURSE_OF_CONSTRUCTION = 'AssetsInCourseOfConstruction',
    CAPITAL_WIP = 'CapitalWip',
    CUMULATIVE_DEPRECIATION_ON_INTANGIBLE_ASSETS = 'CumulativeDepreciationOnIntangibleAssets',
    INTANGIBLE_ASSETS_UNDER_DEVELOPMENT = 'IntangibleAssetsUnderDevelopment',
    LAND_ASSET = 'LandAsset',
    NON_CURRENT_ASSETS = 'NonCurrentAssets',
    PARTICIPATING_INTERESTS = 'ParticipatingInterests',
    PROVISIONS_FIXED_ASSETS = 'ProvisionsFixedAssets',

    // Other Asset
    LEASE_BUYOUT = 'LeaseBuyout',
    OTHER_LONG_TERM_ASSETS = 'OtherLongTermAssets',
    SECURITY_DEPOSITS = 'SecurityDeposits',
    ACCUMULATED_AMORTIZATION_OF_OTHER_ASSETS = 'AccumulatedAmortizationOfOtherAssets',
    GOODWILL = 'Goodwill',
    LICENSES = 'Licenses',  // (default)
    ORGANIZATIONAL_COSTS = 'OrganizationalCosts',
    ASSETS_HELD_FOR_SALE = 'AssetsHeldForSale',
    AVAILABLE_FOR_SALE_FINANCIAL_ASSETS = 'AvailableForSaleFinancialAssets',
    DEFERRED_TAX = 'DeferredTax',
    INVESTMENTS = 'Investments',
    LONG_TERM_INVESTMENTS = 'LongTermInvestments',
    LONG_TERM_LOANS_AND_ADVANCES_TO_RELATED_PARTIES = 'LongTermLoansAndAdvancesToRelatedParties',
    OTHER_INTANGIBLE_ASSETS = 'OtherIntangibleAssets',
    OTHER_LONG_TERM_INVESTMENTS = 'OtherLongTermInvestments',
    OTHER_LONG_TERM_LOANS_AND_ADVANCES = 'OtherLongTermLoansAndAdvances',
    PREPAYMENTS_AND_ACCRUED_INCOME = 'PrepaymentsAndAccruedIncome',
    PROVISIONS_NON_CURRENT_ASSETS = 'ProvisionsNonCurrentAssets',

    // Accounts Receivable
    ACCOUNTS_RECEIVABLE = 'AccountsReceivable',

    // Equity
    OPENING_BALANCE_EQUITY = 'OpeningBalanceEquity',    // (default)
    PARTNERS_EQUITY = 'PartnersEquity',
    RETAINED_EARNINGS = 'RetainedEarnings',
    ACCUMULATED_ADJUSTMENT = 'AccumulatedAdjustment',
    OWNERS_EQUITY = 'OwnersEquity',
    PAID_IN_CAPITAL_OR_SURPLUS = 'PaidInCapitalOrSurplus',
    PARTNER_CONTRIBUTIONS = 'PartnerContributions',
    PARTNER_DISTRIBUTIONS = 'PartnerDistributions',
    PREFERRED_STOCK = 'PreferredStock',
    COMMON_STOCK = 'CommonStock',
    TREASURY_STOCK = 'TreasuryStock',
    ESTIMATED_TAXES = 'EstimatedTaxes',
    HEALTHCARE = 'Healthcare',
    PERSONAL_INCOME = 'PersonalIncome',
    PERSONAL_EXPENSE = 'PersonalExpense',
    ACCUMULATED_OTHER_COMPREHENSIVE_INCOME = 'AccumulatedOtherComprehensiveIncome',
    CALLED_UP_SHARE_CAPITAL = 'CalledUpShareCapital',
    CAPITAL_RESERVES = 'CapitalReserves',
    DIVIDEND_DISBURSED = 'DividendDisbursed',
    EQUITY_IN_EARNINGS_OF_SUBSIDUARIES = 'EquityInEarningsOfSubsiduaries',
    INVESTMENT_GRANTS = 'InvestmentGrants',
    MONEY_RECEIVED_AGAINST_SHARE_WARRANTS = 'MoneyReceivedAgainstShareWarrants',
    OTHER_FREE_RESERVES = 'OtherFreeReserves',
    SHARE_APPLICATION_MONEY_PENDING_ALLOTMENT = 'ShareApplicationMoneyPendingAllotment',
    SHARE_CAPITAL = 'ShareCapital',
    FUNDS = 'Funds',

    // Expense
    ADVERTISING_PROMOTIONAL = 'AdvertisingPromotional',
    BAD_DEBTS = 'BadDebts',
    BANK_CHARGES = 'BankCharges',
    CHARITABLE_CONTRIBUTIONS = 'CharitableContributions',
    COMMISSIONS_AND_FEES = 'CommissionsAndFees',
    ENTERTAINMENT = 'Entertainment',
    ENTERTAINMENT_MEALS = 'EntertainmentMeals',
    EQUIPMENT_RENTAL = 'EquipmentRental',
    FINANCE_COSTS = 'FinanceCosts',
    GLOBAL_TAX_EXPENSE = 'GlobalTaxExpense',
    INSURANCE = 'Insurance',
    INTEREST_PAID = 'InterestPaid',
    LEGAL_PROFESSIONAL_FEES = 'LegalProfessionalFees',
    OFFICE_EXPENSES = 'OfficeExpenses',
    OFFICE_GENERAL_ADMINISTRATIVE_EXPENSES = 'OfficeGeneralAdministrativeExpenses',
    OTHER_BUSINESS_EXPENSES = 'OtherBusinessExpenses',
    OTHER_MISCELLANEOUS_SERVICE_COST = 'OtherMiscellaneousServiceCost',
    PROMOTIONAL_MEALS = 'PromotionalMeals',
    RENT_OR_LEASE_OF_BUILDINGS = 'RentOrLeaseOfBuildings',
    REPAIR_MAINTENANCE = 'RepairMaintenance',
    SHIPPING_FREIGHT_DELIVERY = 'ShippingFreightDelivery',
    SUPPLIES_MATERIALS = 'SuppliesMaterials',
    TRAVEL = 'Travel',  // (default)
    TRAVEL_MEALS = 'TravelMeals',
    UTILITIES = 'Utilities',
    AUTO = 'Auto',
    COST_OF_LABOR = 'CostOfLabor',
    DUES_SUBSCRIPTIONS = 'DuesSubscriptions',
    PAYROLL_EXPENSES = 'PayrollExpenses',
    TAXES_PAID = 'TaxesPaid',
    UNAPPLIED_CASH_BILL_PAYMENT_EXPENSE = 'UnappliedCashBillPaymentExpense',
    AMORTIZATION_EXPENSE = 'AmortizationExpense',
    APPROPRIATIONS_TO_DEPRECIATION = 'AppropriationsToDepreciation',
    BORROWING_COST = 'BorrowingCost',
    DISTRIBUTION_COSTS = 'DistributionCosts',
    EXTERNAL_SERVICES = 'ExternalServices',
    EXTRAORDINARY_CHARGES = 'ExtraordinaryCharges',
    INCOME_TAX_EXPENSE = 'IncomeTaxExpense',
    LOSS_ON_DISCONTINUED_OPERATIONS_NET_OF_TAX = 'LossOnDiscontinuedOperationsNetOfTax',
    MANAGEMENT_COMPENSATION = 'ManagementCompensation',
    OTHER_CURRENT_OPERATING_CHARGES = 'OtherCurrentOperatingCharges',
    OTHER_EXTERNAL_SERVICES = 'OtherExternalServices',
    OTHER_RENTAL_COSTS = 'OtherRentalCosts',
    OTHER_SELLING_EXPENSES = 'OtherSellingExpenses',
    PROJECT_STUDIES_SURVEYS_ASSESSMENTS = 'ProjectStudiesSurveysAssessments',
    PURCHASES_REBATES = 'PurchasesRebates',
    SHIPPING_AND_DELIVERY_EXPENSE = 'ShippingAndDeliveryExpense',
    STAFF_COSTS = 'StaffCosts',
    SUNDRY = 'Sundry',
    TRAVEL_EXPENSES_GENERAL_AND_ADMIN_EXPENSES = 'TravelExpensesGeneralAndAdminExpenses',
    TRAVEL_EXPENSES_SELLING_EXPENSE = 'TravelExpensesSellingExpense',

    // Other Expense
    DEPRECIATION = 'Depreciation',  // (default)
    EXCHANGE_GAIN_OR_LOSS = 'ExchangeGainOrLoss',
    OTHER_MISCELLANEOUS_EXPENSE = 'OtherMiscellaneousExpense',
    PENALTIES_SETTLEMENTS = 'PenaltiesSettlements',
    AMORTIZATION = 'Amortization',
    GAS_AND_FUEL = 'GasAndFuel',
    HOME_OFFICE = 'HomeOffice',
    HOME_OWNER_RENTAL_INSURANCE = 'HomeOwnerRentalInsurance',
    OTHER_HOME_OFFICE_EXPENSES = 'OtherHomeOfficeExpenses',
    MORTGAGE_INTEREST = 'MortgageInterest',
    RENT_AND_LEASE = 'RentAndLease',
    REPAIRS_AND_MAINTENANCE = 'RepairsAndMaintenance',
    PARKING_AND_TOLLS = 'ParkingAndTolls',
    VEHICLE = 'Vehicle',
    VEHICLE_INSURANCE = 'VehicleInsurance',
    VEHICLE_LEASE = 'VehicleLease',
    VEHICLE_LOAN_INTEREST = 'VehicleLoanInterest',
    VEHICLE_LOAN = 'VehicleLoan',
    VEHICLE_REGISTRATION = 'VehicleRegistration',
    VEHICLE_REPAIRS = 'VehicleRepairs',
    OTHER_VEHICLE_EXPENSES = 'OtherVehicleExpenses',
    WASH_AND_ROAD_SERVICES = 'WashAndRoadServices',
    DEFERRED_TAX_EXPENSE = 'DeferredTaxExpense',
    DEPLETION = 'Depletion',
    EXCEPTIONAL_ITEMS = 'ExceptionalItems',
    EXTRAORDINARY_ITEMS = 'ExtraordinaryItems',
    INCOME_TAX_OTHER_EXPENSE = 'IncomeTaxOtherExpense',
    MAT_CREDIT = 'MatCredit',
    PRIOR_PERIOD_ITEMS = 'PriorPeriodItems',
    TAX_ROUNDOFF_GAIN_OR_LOSS = 'TaxRoundoffGainOrLoss',

    // Cost of Goods Sold
    EQUIPMENT_RENTAL_COS = 'EquipmentRentalCos',
    OTHER_COSTS_OF_SERVICE_COS = 'OtherCostsOfServiceCos',
    SHIPPING_FREIGHT_DELIVERY_COS = 'ShippingFreightDeliveryCos',
    SUPPLIES_MATERIALS_COGS = 'SuppliesMaterialsCogs',
    COST_OF_LABOR_COS = 'CostOfLaborCos',   // (default)
    COST_OF_SALES = 'CostOfSales',
    FREIGHT_AND_DELIVERY_COST = 'FreightAndDeliveryCost',

    // Accounts Payable
    ACCOUNTS_PAYABLE = 'AccountsPayable',
    OUTSTANDING_DUES_MICRO_SMALL_ENTERPRISE = 'OutstandingDuesMicroSmallEnterprise',
    OUTSTANDING_DUES_OTHER_THAN_MICRO_SMALL_ENTERPRISE = 'OutstandingDuesOtherThanMicroSmallEnterprise',

    // Credit Card
    CREDIT_CARD = 'CreditCard',

    // Long Term Liability
    NOTES_PAYABLE = 'NotesPayable', // (default)
    OTHER_LONG_TERM_LIABILITIES = 'OtherLongTermLiabilities',
    SHAREHOLDER_NOTES_PAYABLE = 'ShareholderNotesPayable',
    ACCRUALS_AND_DEFERRED_INCOME = 'AccrualsAndDeferredIncome',
    ACCRUED_LONG_LERM_LIABILITIES = 'AccruedLongLermLiabilities',
    ACCRUED_VACATION_PAYABLE = 'AccruedVacationPayable',
    BANK_LOANS = 'BankLoans',
    DEBTS_RELATED_TO_PARTICIPATING_INTERESTS = 'DebtsRelatedToParticipatingInterests',
    DEFERRED_TAX_LIABILITIES = 'DeferredTaxLiabilities',
    GOVERNMENT_AND_OTHER_PUBLIC_AUTHORITIES = 'GovernmentAndOtherPublicAuthorities',
    GROUP_AND_ASSOCIATES = 'GroupAndAssociates',
    LIABILITIES_RELATED_TO_ASSETS_HELD_FOR_SALE = 'LiabilitiesRelatedToAssetsHeldForSale',
    LONG_TERM_BORROWINGS = 'LongTermBorrowings',
    LONG_TERM_DEBIT = 'LongTermDebit',
    LONG_TERM_EMPLOYEE_BENEFIT_OBLIGATIONS = 'LongTermEmployeeBenefitObligations',
    OBLIGATIONS_UNDER_FINANCE_LEASES = 'ObligationsUnderFinanceLeases',
    OTHER_LONG_TERM_PROVISIONS = 'OtherLongTermProvisions',
    PROVISION_FOR_LIABILITIES = 'ProvisionForLiabilities',
    PROVISIONS_NON_CURRENT_LIABILITIES = 'ProvisionsNonCurrentLiabilities',
    STAFF_AND_RELATED_LONG_TERM_LIABILITY_ACCOUNTS = 'StaffAndRelatedLongTermLiabilityAccounts',

    // Other Current Liability
    DIRECT_DEPOSIT_PAYABLE = 'DirectDepositPayable',
    LINE_OF_CREDIT = 'LineOfCredit',
    LOAN_PAYABLE = 'LoanPayable',
    GLOBAL_TAX_PAYABLE = 'GlobalTaxPayable',
    GLOBAL_TAX_SUSPENSE = 'GlobalTaxSuspense',
    OTHER_CURRENT_LIABILITIES = 'OtherCurrentLiabilities',  // (default)
    PAYROLL_CLEARING = 'PayrollClearing',
    PAYROLL_TAX_PAYABLE = 'PayrollTaxPayable',
    PREPAID_EXPENSES_PAYABLE = 'PrepaidExpensesPayable',
    RENTS_IN_TRUST_LIABILITY = 'RentsInTrustLiability',
    TRUST_ACCOUNTS_LIABILITIES = 'TrustAccountsLiabilities',
    FEDERAL_INCOME_TAX_PAYABLE = 'FederalIncomeTaxPayable',
    INSURANCE_PAYABLE = 'InsurancePayable',
    SALES_TAX_PAYABLE = 'SalesTaxPayable',
    STATE_LOCAL_INCOME_TAX_PAYABLE = 'StateLocalIncomeTaxPayable',
    ACCRUED_LIABILITIES = 'AccruedLiabilities',
    CURRENT_LIABILITIES = 'CurrentLiabilities',
    CURRENT_PORTION_EMPLOYEE_BENEFITS_OBLIGATIONS = 'CurrentPortionEmployeeBenefitsObligations',
    CURRENT_PORTION_OF_OBLIGATIONS_UNDER_FINANCE_LEASES = 'CurrentPortionOfObligationsUnderFinanceLeases',
    CURRENT_TAX_LIABILITY = 'CurrentTaxLiability',
    DIVIDENDS_PAYABLE = 'DividendsPayable',
    DUTIES_AND_TAXES = 'DutiesAndTaxes',
    INTEREST_PAYABLES = 'InterestPayables',
    PROVISION_FOR_WARRANTY_OBLIGATIONS = 'ProvisionForWarrantyObligations',
    PROVISIONS_CURRENT_LIABILITIES = 'ProvisionsCurrentLiabilities',
    SHORT_TERM_BORROWINGS = 'ShortTermBorrowings',
    SOCIAL_SECURITY_AGENCIES = 'SocialSecurityAgencies',
    STAFF_AND_RELATED_LIABILITY_ACCOUNTS = 'StaffAndRelatedLiabilityAccounts',
    SUNDRY_DEBTORS_AND_CREDITORS = 'SundryDebtorsAndCreditors',
    TRADE_AND_OTHER_PAYABLES = 'TradeAndOtherPayables',

    // Income
    NON_PROFIT_INCOME = 'NonProfitIncome',
    OTHER_PRIMARY_INCOME = 'OtherPrimaryIncome',    // (default)
    SALES_OF_PRODUCT_INCOME = 'SalesOfProductIncome',
    SERVICE_FEE_INCOME = 'ServiceFeeIncome',
    DISCOUNTS_REFUNDS_GIVEN = 'DiscountsRefundsGiven',
    UNAPPLIED_CASH_PAYMENT_INCOME = 'UnappliedCashPaymentIncome',
    CASH_RECEIPT_INCOME = 'CashReceiptIncome',
    OPERATING_GRANTS = 'OperatingGrants',
    OTHER_CURRENT_OPERATING_INCOME = 'OtherCurrentOperatingIncome',
    OWN_WORK_CAPITALIZED = 'OwnWorkCapitalized',
    REVENUE_GENERAL = 'RevenueGeneral',
    SALES_RETAIL = 'SalesRetail',
    SALES_WHOLESALE = 'SalesWholesale',
    SAVINGS_BY_TAX_SCHEME = 'SavingsByTaxScheme',

    // Other Income
    DIVIDEND_INCOME = 'DividendIncome',
    INTEREST_EARNED = 'InterestEarned',
    OTHER_INVESTMENT_INCOME = 'OtherInvestmentIncome',  // (default)
    OTHER_MISCELLANEOUS_INCOME = 'OtherMiscellaneousIncome',
    TAX_EXEMPT_INTEREST = 'TaxExemptInterest',
    GAIN_LOSS_ON_SALE_OF_FIXED_ASSETS = 'GainLossOnSaleOfFixedAssets',
    GAIN_LOSS_ON_SALE_OF_INVESTMENTS = 'GainLossOnSaleOfInvestments',
    LOSS_ON_DISPOSAL_OF_ASSETS = 'LossOnDisposalOfAssets',
    OTHER_OPERATING_INCOME = 'OtherOperatingIncome',
    UNREALISED_LOSS_ON_SECURITIES_NET_OF_TAX = 'UnrealisedLossOnSecuritiesNetOfTax',
}

export enum PaymentType {
    PAYMENT = 'PAYMENT',
    BILL_PAYMENT = 'BILL_PAYMENT'
}

export enum QuickBooksPaymentMethodTypeEnum {
    CREDIT_CARD= 'CREDIT_CARD',
    NON_CREDIT_CARD = 'NON_CREDIT_CARD'
}

export enum OrganizationProviderAccountStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE'
}

export enum PickupStatus {
    SCHEDULED = 'SCHEDULED',
    DISPATCHED = 'DISPATCHED',
    PICKED_UP = 'PICKED_UP',
    CLOSED = 'CLOSED',
    FAILED = 'FAILED',
    CANCELLED = 'CANCELLED',
}

export enum PaymentTypeOption {
    PREPAID = 'PREPAID',
    COLLECT = 'COLLECT',
}

export enum CollectAccountType {
    FREIGHT = 'FREIGHT',
    MAILING = 'MAILING',
}
export enum RegistrationState {
    USER_VERIFIED = 'USER_VERIFIED',
    PENDING_EMAIL_CONFIRMATION = 'PENDING_EMAIL_CONFIRMATION',
    PENDING_ADMIN_APPROVAL = 'PENDING_ADMIN_APPROVAL',
    FAILED = 'FAILED'
}

export enum QuotePriceType {
    LOWEST_PRICE = 'LOWEST_PRICE',
    LAZR_CHOICE = 'LAZR_CHOICE',
    BEST_VALUE = 'BEST_VALUE',
    FASTEST =  'FASTEST',
    ECO_FRIENDLY = 'ECO_FRIENDLY',
}

export enum B13aFilingOption {
    NOT_REQUIRED = 'NOT_REQUIRED',
    MANUALLY_ATTACHED =  'MANUALLY_ATTACHED',
}
export enum ExportComplianceStatement {
    NDR1 = '1',
    NDR2 = '2',
    NDR3 = '3',
    NDR4 = '4',
    NDR5 = '5',
    NDR6 = '6',
    NDR7 = '7',
    NDR8 = '8',
    NDR9 = '9',
    NDR10 = '10',
    NDR11 = '11',
    NDR12 = '12',
    NDR13 = '13',
    NDR14 = '14',
    NDR15 = '15',
    NDR16 = '16',
    'NO EEI 30.37(f)' ='NOEEI 30.37(f)'
}

export enum ShippingMode {
    INTERMODAL = 'INTERMODAL',
    ROAD = 'ROAD',
    GROUND = 'GROUND',
    AIR = 'AIR',
    UNKNOWN = 'UNKNOWN'
}

export enum LaneType {
    DIRECT = 'DIRECT',
    INTERLINE = 'INTERLINE',
    UNKNOWN = 'UNKNOWN'
}

export enum EtdStatus {
    FAILED = 'FAILED',
    SUCCEEDED = 'SUCCEEDED',
}

export enum MarginVersion {
    V1 = 'v1',
    V2 = 'v2',
    V3 = 'v3',
    V4 = 'v4',
    V5 = 'v5',
    V6 = 'v6',
    V7 = 'v7',
    V8 = 'v8',
    V9 = 'v9',
    V10 = 'v10',
    V11 = 'v11',
    V12 = 'v12',
    V13 = 'v13',
}

export enum InvoiceEdiSentStatus {
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
}

export enum UserUiType {
    TABLE = 'TABLE',
    AGREEMENT = 'AGREEMENT'
}

export enum FedexLocation {
    FRONT = 'FRONT',
    NONE = 'NONE',
    REAR = 'REAR',
    SIDE = 'SIDE',
}

export enum FedexCarrierCode {
    FDXE = 'FDXE',
    FDXG = 'FDXG'
}

export enum ConsolidationFileType {
    CSV = 'CSV',
    PDF =  'PDF',
}

export enum TrackingFilterStatus {
    DISPATCHED = 'DISPATCHED',
    PICKUP = 'PICKUP',
    IN_TRANSIT = 'IN_TRANSIT',
    DELAYED = 'DELAYED',
    DELIVERY = 'DELIVERY',
    EXCEPTION = 'EXCEPTION',
}


export enum ApiServiceAction {
    ADD = 'ADD',
    ASSIGN = 'ASSIGN',
    IDLE = 'IDLE',
    GET = 'GET',
    GETBYID = 'GETBYID',
    GETBYTYPENAME = 'GETBYTYPENAME',
    GETBYCODE = 'GETBYCODE',
    NEW = 'NEW',
    EDIT = 'EDIT',
    DELETE = 'DELETE',
    LIST = 'LIST',
    DISABLE = 'DISABLE',
    ENABLE = 'ENABLE',
    GETBYUSERID='GETBYUSERID'
}

export enum BatchShipmentStatus {
    STARTED = 'STARTED',
    PROCESSED = 'PROCESSED',
    FAILED = 'FAILED'
}

export enum Onboarding {
    API = 'API',
    GMX = 'GMX',
    GMX_IMPORT = 'GMX_IMPORT',
    WEBSITE = 'WEBSITE',
    GOOGLE_ADS = 'GOOGLE_ADS',
    TRACKING_PAGE = 'TRACKING_PAGE',
    CANADA_CHAMBER_OF_COMMERCE = 'CANADA_CHAMBER_OF_COMMERCE',
}

export enum BatteryMaterial {
    LITHIUM_METAL = 'LITHIUM_METAL',
    LITHIUM_ION = 'LITHIUM_ION'
}
export enum BatteryPacking {
    //UPS ONLY
    ALONE_IN_BOX = 'ALONE_IN_BOX',
    //
    PACKED_WITH_EQUIPMENT = 'PACKED_WITH_EQUIPMENT',
    CONTAINED_IN_EQUIPMENT = 'CONTAINED_IN_EQUIPMENT'
}

export enum UIAccess {
    V1 = 'V1',
    V2 = 'V2',
    ALL = 'ALL',
}

export enum QuickbooksMessageStatus {
    NEW= 'NEW',
    FAILED = 'FAILED',
    SUCCESS = 'SUCCESS'
}

export enum QuoteCoverageType {
    BASE = 'BASE',
    LAZR = 'LAZR'
}

export enum InvoiceVersion {
    V1 = 'v1',
    V2 = 'v2',
}

export enum ThreePlRegionZone {
    ORIGIN = 'ORIGIN',
    DESTINATION = 'DESTINATION',
    BOTH = 'BOTH'
}

export enum ThreePlRegionType {
    ALLOW = 'ALLOW',
    NOT_ALLOW = 'NOT_ALLOW'
}

export enum CommercialInvoiceAndDocShipment {
    CUSTOMSBROKER = 'CUSTOMSBROKER',
    SHOWALL  ='SHOWALL'
}// AGREEMENTS
export enum AgreementOption {
    MANAGED = 'MANAGED',
    UNMANAGED = 'UNMANAGED'
}

export enum AgreementType {
    TERMSANDCONDITIONS = 'TERMSANDCONDITIONS',
    POLICY = 'POLICY',
    PACKAGING_GUIDELINE = 'PACKAGING_GUIDELINE'
}

export enum ActiveAgreements {
    PACKAGING_GUIDELINE = 'PKG20231213'
}

export enum RfqClientThreeplAssignedStatus {
    ACCEPTED    = 'ACCEPTED', // WINNER (Client decision)
    REJECTED    = 'REJECTED', // LOSER (UI only)
    DECLINED    = 'DECLINED', // 3PL decision
    IN_PROGRESS = 'IN_PROGRESS',
    NOT_STARTED = 'NOT_STARTED',
    SUBMITTED   = 'SUBMITTED',
}

export enum OrganizationThreePlStatus {
    ACTIVE = 'ACTIVE',
    DISABLED = 'DISABLED'
}
